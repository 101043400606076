import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import mapSDK from "@tomtom-international/web-sdk-maps";
import { useQuery } from "@tanstack/react-query";
import liveMonitoringService from "../services/live-monitoring-service";
import { Card, Flex, notification, Spin } from "antd";
import TomtomMapComponent from "../../../shared/component/MapComponent/TomtomMapComponent";
import ReactDOMServer, { renderToStaticMarkup } from "react-dom/server";
import { CopyOutlined } from "@ant-design/icons";
import { SelectDs, ButtonDs } from "design-system";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import { DeviceRoute } from "../models/route";
import { TaskInfo, Trip } from "../models/trip";

interface TruckDetailMapProps {
  tripDetail: Trip | { trip_info: null; task_list: TaskInfo[] };
  trackingId: string;
  isLoadingDriverDetail: boolean;
}

export interface TruckDetailMapRef {
  moveToMarker: (key: string) => void;
}

const TruckDetailMap = forwardRef<TruckDetailMapRef, TruckDetailMapProps>(
  ({ tripDetail, trackingId, isLoadingDriverDetail }, ref) => {
    const tomTomApiKey = process.env.REACT_APP_TOMTOM_API_KEY as string;
    const mapContainer = useRef<HTMLDivElement>(null);
    const tomtomMap = useRef<mapSDK.Map>({} as mapSDK.Map);
    const markers = useRef<Map<string, any>>(new Map());
    const [isLoadingMap, setIsLoadingMap] = useState<boolean>(false);
    const fetchInterval = 2 * (1000 * 60);
    const [selectedSource, setSelectedSource] = useState("all");
    const [devicesRouteHistory, setDevicesRouteHistory] = useState<DeviceRoute[]>([]);
    const sourceOptions = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Mobile Device",
        value: "mobile_device",
      },
      {
        label: "GPS Hardware",
        value: "gps_hardware",
      },
    ];

    useImperativeHandle(ref, () => ({
      moveToMarker: (key: string) => {
        moveToMarker(key);
      },
    }));

    const {
      data: routesData = [],
      isLoading: isLoadingRouteHistory,
      isError,
      error,
    } = useQuery({
      queryKey: ["driver/route-history", trackingId],
      queryFn: () => {
        return liveMonitoringService.getRouteHistory(trackingId).then((res) => {
          setDevicesRouteHistory(res);
          return res;
        });
      },
      retry: 1,
      refetchInterval: fetchInterval,
      refetchOnWindowFocus: false,
      enabled: () => (trackingId ? true : false),
    });

    if (isError) {
      console.log(error);
    }

    useEffect(() => {
      markers.current = new Map();
      renderMap();
      const mapContainerDiv = document.getElementsByClassName("wrap-map")[0];
      let resizeObserver = new ResizeObserver(() => {});
      if (mapContainerDiv) {
        resizeObserver = new ResizeObserver(() => {
          tomtomMap.current.resize();
        });
        resizeObserver.observe(mapContainerDiv);
      }

      const mapLoadListener = () => {
        setIsLoadingMap(true);
      };
      tomtomMap.current.on("load", mapLoadListener);

      return () => {
        if (mapContainerDiv) {
          resizeObserver.unobserve(mapContainerDiv);
        }
        tomtomMap.current.off("load", mapLoadListener);
      };
    }, []);

    useEffect(() => {
      if (tripDetail && routesData && isLoadingMap) {
        handleDrawmap();
      }
    }, [isLoadingMap, tripDetail, routesData]);

    const renderMap = () => {
      const renderMap = mapSDK.map({
        key: tomTomApiKey as string,
        container: mapContainer.current as HTMLDivElement,
        center: [100.523186, 13.736717],
        zoom: 5.3,
      });
      tomtomMap.current = renderMap;
    };

    const getColorCode = (lineIndex: number, source: string) => {
      const mobileColors = ["#0F53FF", "#CF0FFF", "#00BF9C", "#B5D300"];
      if (source === "mobile_device") {
        const index = lineIndex - 1;
        return mobileColors[index % mobileColors.length];
      } else {
        return "#FF910F";
      }
    };

    const addMapLayers = (map: any, id: number, coordinates: any[], source: string) => {
      try {
        const layerData = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                properties: {},
                coordinates: coordinates,
              },
            },
          ],
        };

        const lineColor = getColorCode(id, source);
        const layerId = `${source}-route-${id}`;
        const routeLayer = {
          id: layerId,
          type: "line",
          paint: {
            // "line-color": "#0f53ff",
            "line-color": lineColor,
            "line-width": 5,
          },
          layout: {},
          source: {
            type: "geojson",
            data: layerData,
          },
        };

        if (map.getLayer(layerId) !== undefined) {
          map.getSource(layerId).setData(layerData);
        } else {
          map.addLayer(routeLayer);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const drawRouteHistory = (routesData: any[]) => {
      const sourceNo: Record<string, number> = {};
      if (routesData !== undefined && routesData.length > 0) {
        routesData.forEach((record) => {
          if (!sourceNo[record.source]) {
            sourceNo[record.source] = 1;
          } else {
            sourceNo[record.source]++;
          }
          addMapLayers(tomtomMap.current, sourceNo[record.source], record.coordinates, record.source);
        });
      }
    };

    const RenderStoreMarker = (index: string) => {
      return (
        <div className="wrap-store-marker">
          <span className="stop">{index}</span>
          <div id="store-marker"></div>
        </div>
      );
    };

    const [notificationComponent, contextHolderNoti] = notification.useNotification();

    const diaplayNotification = (type: "success" | "error", message: string, topic?: string) => {
      return NotificationComponent({
        notification: notificationComponent,
        type,
        topic: topic,
        message: message,
        closeIcon: true,
      });
    };

    const copyToClipBoard = async (lat: string, long: string) => {
      try {
        await navigator.clipboard.writeText(`${lat}, ${long}`);
        diaplayNotification("success", "Lat,Long Copied Successfully");
      } catch (err) {
        console.log(err);
        diaplayNotification("error", "Lat,Long Copied Failed");
      }
    };

    const setStoreMarker = (
      key: string,
      index: number,
      lat: string | undefined,
      long: string | undefined,
      html: string,
    ) => {
      const markerMap = new Map<string, mapSDK.Marker>();

      const markerElement = document.createElement("div");
      const staticElement = renderToStaticMarkup(RenderStoreMarker(index.toString()));
      markerElement.innerHTML = staticElement;

      const marker = new mapSDK.Marker({
        element: markerElement,
        anchor: "center",
      });

      const copyLatlongListener = () => {
        copyToClipBoard(lat || "", long || "");
      };

      const popup = new mapSDK.Popup({ offset: 30, maxWidth: "none" })
        .on("open", () => {
          const button = document.getElementById(`copy-${lat}-${long}`);
          button?.addEventListener("click", copyLatlongListener);
        })
        .on("close", () => {
          const button = document.getElementById(`copy-${lat}-${long}`);
          button?.removeEventListener("click", copyLatlongListener);
        })
        .setHTML(html);

      marker
        .setLngLat([Number(long), Number(lat)])
        .setPopup(popup)
        .addTo(tomtomMap.current);

      markerMap.set(key, marker);

      markers.current.set(key, marker);
    };

    const moveToMarker = (key: string) => {
      const map: any = tomtomMap.current;
      const marker = markers.current.get(key);
      if (marker) {
        map.flyTo({
          center: marker.getLngLat(),
          zoom: 13,
          animate: true,
          duration: 4000,
        });
      }
    };

    const setMarker = (
      key: string,
      markerId: string,
      lat: string | undefined,
      long: string | undefined,
      html: string,
      truckHeading: number,
    ) => {
      const markerMap = new Map<string, mapSDK.Marker>();

      const markerElement = document.createElement("div");
      markerElement.id = markerId;

      let marker = new mapSDK.Marker({
        element: markerElement,
        anchor: "center",
      });

      if (markerId.includes("truck")) {
        marker = new mapSDK.Marker({
          element: markerElement,
          anchor: "center",
          rotation: truckHeading,
        });
      }

      const popup = new mapSDK.Popup({ offset: 30 }).setHTML(html);

      marker
        .setLngLat([Number(long), Number(lat)])
        .setPopup(popup)
        .addTo(tomtomMap.current);

      markerMap.set(key, marker);

      markers.current.set(key, marker);
    };

    const popOverPositionContent = (lat: string, lng: string) => {
      return (
        <span>
          <p style={{ margin: "10px" }}>{`${lat}, ${lng}`}</p>
          <a
            style={{ display: "block", textAlign: "center" }}
            target="_blank"
            href={`https://www.google.com/maps/dir/${lat}, ${lng}`}
            rel="noreferrer"
          >
            Open in Google Maps
          </a>
        </span>
      );
    };

    const popOverContent = (store_list: TaskInfo[], store_number: string, lat: string, long: string) => {
      const storeName = store_list.filter((x) => x.store_number === store_number);
      return (
        <div className="store-map-popup-container">
          <p style={{ flex: 1, marginBottom: 0 }}>สาขา : {storeName ? storeName[0].store_name : ""}</p>
          <ButtonDs
            id={`copy-${lat}-${long}`}
            style={{ paddingLeft: 10 }}
            autoFocus={false}
            type="link"
            color="default"
            icon={<CopyOutlined size={16} />}
          ></ButtonDs>
        </div>
      );
    };

    const popOverContentHTML = (store_list: TaskInfo[], store_number: string, lat: string, long: string) =>
      ReactDOMServer.renderToStaticMarkup(<div>{popOverContent(store_list, store_number, lat, long)}</div>);

    const popOverPositionContentHTML = (lat: string, long: string) =>
      ReactDOMServer.renderToStaticMarkup(<div>{popOverPositionContent(lat, long)}</div>);

    const setLocationMarker = (
      key: string,
      lat: string | undefined,
      long: string | undefined,
      truckHeading: number | null = null,
    ) => {
      const marker = markers.current.get(key);
      if (marker && lat && long) {
        marker.setLngLat([Number(long), Number(lat)]);
      }
      if (truckHeading) {
        marker.setRotation(truckHeading);
      }
    };

    const renderMarker = (tripDetail: Trip | { trip_info: null; task_list: TaskInfo[] }) => {
      if (!tripDetail.trip_info) {
        return;
      }
      const { truck_plate_number, dc_code, dc_location, truck_location, active } = tripDetail.trip_info;
      const dcLat = dc_location.lat;
      const dcLong = dc_location.long;
      const truckLat = truck_location?.lat;
      const truckLong = truck_location?.long;
      const store_list = tripDetail.task_list;
      const truckHeading = truck_location?.heading || 0;

      if (truckLat && truckLong) {
        const htmlContent = popOverPositionContentHTML(truckLat, truckLong);
        const truckMarkerId = active ? "active-marker" : "inactive-marker";
        const oldtruckMaker = markers.current.get(truck_plate_number);
        if (oldtruckMaker) {
          const oldId = oldtruckMaker.getElement().id;
          if (oldId !== truckMarkerId) {
            oldtruckMaker.remove();
          }
        }
        // driverDetail.dc_location.
        if (!markers.current) {
          setMarker(truck_plate_number, truckMarkerId, truckLat, truckLong, htmlContent, truckHeading);
        } else {
          if (!markers.current.get(truck_plate_number)) {
            setMarker(truck_plate_number, truckMarkerId, truckLat, truckLong, htmlContent, truckHeading);
          } else {
            setLocationMarker(truck_plate_number, truckLat, truckLong, truckHeading);
          }
        }
      }

      if (dcLat && dcLong) {
        const htmlContent = popOverPositionContentHTML(dcLat, dcLong);
        if (!markers.current) {
          setMarker(dc_code, "dc-marker", dcLat, dcLong, htmlContent, truckHeading);
        } else {
          if (!markers.current.get(dc_code)) {
            setMarker(dc_code, "dc-marker", dcLat, dcLong, htmlContent, truckHeading);
          } else {
            setLocationMarker(dc_code, dcLat, dcLong);
          }
        }
      }

      if (store_list) {
        store_list.forEach((x: TaskInfo, index) => {
          const htmlContent = popOverContentHTML(store_list, x.store_number, x.lat, x.long);
          if (!markers.current) {
            setStoreMarker(x.store_number, index + 1, x.lat, x.long, htmlContent);
          } else {
            if (!markers.current.get(x.store_number)) {
              setStoreMarker(x.store_number, index + 1, x.lat, x.long, htmlContent);
            } else {
              setLocationMarker(x.store_number, x.lat, x.long);
            }
          }
        });
      }

      const bounds = new mapSDK.LngLatBounds();

      Array.from(markers.current.values()).forEach((marker) => {
        if (marker.getLngLat) {
          bounds.extend(marker.getLngLat());
        }
      });

      tomtomMap.current.fitBounds(bounds, {
        padding: 100,
      });
    };
    const changeMapLayerVisibility = (map: any, layerId: string, visibility: "visible" | "none") => {
      map.setLayoutProperty(layerId, "visibility", visibility);
    };

    const onSelectSource = (value: string) => {
      setSelectedSource(value);
      const sourceNo: Record<string, number> = {};
      devicesRouteHistory.forEach((record) => {
        if (!sourceNo[record.source]) {
          sourceNo[record.source] = 1;
        } else {
          sourceNo[record.source]++;
        }
        const layerId = `${record.source}-route-${sourceNo[record.source]}`;
        if (value === "all") {
          changeMapLayerVisibility(tomtomMap.current, layerId, "visible");
        } else {
          if (record.source === value) {
            changeMapLayerVisibility(tomtomMap.current, layerId, "visible");
          } else {
            changeMapLayerVisibility(tomtomMap.current, layerId, "none");
          }
        }
      });
    };

    const handleDrawmap = () => {
      renderMarker(tripDetail);
      drawRouteHistory(routesData || []);
    };

    return (
      <>
        <Card className="drawer-card" styles={{ body: { padding: 16 } }}>
          <Flex align="center" justify="space-between" style={{ marginBottom: 16 }}>
            <span className="title" style={{ flex: 1 }}>
              Map
            </span>
            <Flex className="source-select" align="center" gap={8}>
              <p>Source: </p>
              <SelectDs
                options={sourceOptions}
                value={selectedSource}
                onChange={onSelectSource}
                style={{ width: 160 }}
              ></SelectDs>
            </Flex>
          </Flex>

          <Spin spinning={isLoadingRouteHistory || isLoadingDriverDetail}>
            <section className="wrap-map">
              <TomtomMapComponent apiKey={tomTomApiKey} mapContainer={mapContainer}></TomtomMapComponent>
            </section>
          </Spin>
          {contextHolderNoti}
        </Card>
      </>
    );
  },
);

export default TruckDetailMap;
