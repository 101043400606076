import ApiClient from "../../../shared/api/ApiClient";

const getEtas = (): Promise<any> => ApiClient.get("/etas").then((res) => res.data);

const downloadLatestFile = (dcCode: string): Promise<any> =>
  ApiClient.get(`/etas/${dcCode}/download/latest`, {
    responseType: "blob",
  }).then((res) => res);

const downloadOriginalFile = (dcCode: string): Promise<any> =>
  ApiClient.get(`/etas/${dcCode}/download`, {
    responseType: "blob",
  }).then((res) => res);

const uploadEta = (dcCode: string, originFileObj: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", originFileObj);
  formData.append("dc_code", dcCode);
  const v2EndPoint = "/v2/delivery-orders/upload";
  return ApiClient.post(`${v2EndPoint}`, formData, { timeout: 3600000 }).then((res) => res.data);
};

const downloadSyncLatestFile = (dcCode: string): Promise<any> =>
  ApiClient.get(`/v2/tms-sync/download`, {
    params: { dc_code: dcCode },
    responseType: "blob",
  }).then((res) => res);

const deliverOrderService = {
  getEtas,
  downloadLatestFile,
  downloadOriginalFile,
  uploadEta,
  downloadSyncLatestFile,
};

export default deliverOrderService;
