import Modal from "antd/es/modal/Modal";
import { Card, Flex, notification, Space } from "antd";
import { ButtonDs } from "design-system";
import ConfirmModal from "../../../shared/component/ConfirmModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Form, FormDs, InputDs, SelectDs } from "design-system";
import { provincesOptions } from "../../../shared/service/province-list-option";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import storeMasterService from "../services/store-master-service";
import { StoreType } from "../models/store";
import { AxiosError } from "axios";
import { HTTP_STATUS_CODE } from "../../../utils/Constants";
import { phoneNumberValidator } from "../../../utils/validators";
import { formatPhoneNumber } from "../../../utils/normalizers";

interface CreateStoreModalProps {
  open: boolean;
  onClose: () => void;
  onOk: (newStore: StoreType) => void;
}

export interface CreateStoreManagementFormValues {
  company: string;
  store_number: string;
  store_name: string;
  province: string;
  latlong: string;
  mobile_phone: string;
  remark: string;
}

enum CreateStoreModalState {
  CONFIRM_CREATE = "CONFIRM_CREATE",
  CANCEL_CREATE = "CANCEL_CREATE",
}

const CreateStoreModal = ({ open = true, onClose, onOk }: CreateStoreModalProps) => {
  const [openConfirmModalState, setOpenConfirmModalState] = useState<null | CreateStoreModalState>(null);
  const [form] = Form.useForm<CreateStoreManagementFormValues>();
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const provinces = provincesOptions.slice(1);
  const companies = [
    {
      label: "CJ",
      value: "CJ",
    },
    {
      label: "CJx",
      value: "CJx",
    },
    {
      label: "TD",
      value: "TD",
    },
  ];

  const openNotification = (type: "success" | "error", topic = "", message = "") => {
    NotificationComponent({
      notification: notificationComponent,
      type,
      topic,
      message,
    });
  };

  const onConfirmCreate = () => {
    form
      .validateFields()
      .then(() => {
        setOpenConfirmModalState(CreateStoreModalState.CONFIRM_CREATE);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      storeMasterService.isloadingTable.next(true);
      const formValues: CreateStoreManagementFormValues = form.getFieldsValue();
      const payload: Partial<StoreType> = {
        company: formValues.company,
        store_number: formValues.store_number,
        store_name: formValues.store_name,
        province: formValues.province,
        lat: trimValue(formValues.latlong.split(",")[0]),
        long: trimValue(formValues.latlong.split(",")[1]),
        mobile_phone: formValues.mobile_phone,
        remark: formValues.remark,
      };
      const res = await storeMasterService.createStore(payload);
      setOpenConfirmModalState(null);
      form.resetFields();
      onClose();
      onOk({ ...res, is_new_store: true });
      openNotification("success", "", "New store added successfully");
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
      storeMasterService.isloadingTable.next(false);
    }
  };

  const handleError = (err: any) => {
    let message = "An error occurred while saving the store data, Please try again";
    if (err instanceof AxiosError) {
      message =
        err.response?.status === HTTP_STATUS_CODE.DUPLICATE ? "This store number already exists" : message;
    }
    openNotification("error", "Failed to save the store data", message);
  };

  const trimValue = (value: string) => {
    return value.trim();
  };

  return (
    <>
      <Modal
        title="New Store"
        className="modal-create-store"
        centered
        open={open}
        width={950}
        onCancel={() => setOpenConfirmModalState(CreateStoreModalState.CANCEL_CREATE)}
        footer={
          <>
            <ButtonDs
              type="default"
              onClick={() => setOpenConfirmModalState(CreateStoreModalState.CANCEL_CREATE)}
            >
              Cancel
            </ButtonDs>
            <ButtonDs htmlType="submit" type="primary" onClick={onConfirmCreate}>
              Save
            </ButtonDs>
          </>
        }
      >
        <Card style={{ padding: "16px" }}>
          <h3>General Information</h3>
          <FormDs form={form} initialValues={{ remark: "" }} layout="vertical" requiredMark={true}>
            <Flex gap={16}>
              <Form.Item
                name="company"
                label="Company"
                rules={[
                  {
                    required: true,
                    message: "Please select company",
                  },
                ]}
              >
                <SelectDs placeholder="Select company" options={companies} style={{ width: "330px" }} />
              </Form.Item>
            </Flex>
            <Flex gap={16}>
              <Form.Item
                name="store_number"
                label="Store Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter the store number",
                  },
                  {
                    min: 4,
                    message: "Store number must be at least 4 characters long",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                    message: "Store number must be in English characters and numbers",
                  },
                ]}
                normalize={trimValue}
              >
                <InputDs placeholder="Enter the store number" />
              </Form.Item>
              <Form.Item
                name="store_name"
                label="Store Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the store name",
                  },
                ]}
                normalize={trimValue}
              >
                <InputDs placeholder="Enter the store name" />
              </Form.Item>
            </Flex>
            <Flex gap={16}>
              <Form.Item
                name="province"
                label="Province"
                rules={[
                  {
                    required: true,
                    message: "Please select province",
                  },
                ]}
                required
              >
                <SelectDs
                  className="ant-select-lg ant-select-in-form-item select-ds"
                  placeholder="Select province"
                  style={{ width: "330px" }}
                  options={provinces}
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name="latlong"
                label="Lat, Long"
                rules={[
                  {
                    pattern: new RegExp(/^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/),
                    message: "Incorrect lat, long format",
                  },
                  {
                    required: true,
                    message: "Please enter the lat, long",
                  },
                ]}
                normalize={trimValue}
              >
                <InputDs placeholder="Enter the lat,long" />
              </Form.Item>
            </Flex>
            <Flex gap={16}>
              <Form.Item
                name="mobile_phone"
                label="Mobile Phone"
                help="Specify mobile phone (digits only), No spaces or special characters"
                rules={[
                  {
                    required: true,
                    message: "Please enter the mobile phone",
                  },
                  {
                    validator: phoneNumberValidator,
                  },
                ]}
                normalize={(value) => formatPhoneNumber(value)}
              >
                <InputDs placeholder="Enter the mobile phone" />
              </Form.Item>
              <Form.Item name="remark" label="Remark">
                <InputDs placeholder="Enter the remark" />
              </Form.Item>
            </Flex>
          </FormDs>
        </Card>
      </Modal>
      <ConfirmModal
        title={
          <Space>
            <QuestionCircleOutlined /> Confirm store addition
          </Space>
        }
        onClickConfirmCancel={() => {
          setOpenConfirmModalState(null);
        }}
        onClickConfirmOK={onSubmit}
        open={openConfirmModalState === CreateStoreModalState.CONFIRM_CREATE}
        loading={isLoading}
        confirmText="Confirm"
        cancelText="Cancel"
      >
        <Flex vertical>
          <p>{`Store: ${form.getFieldValue("store_number")} (${form.getFieldValue("store_name")})`}</p>
          <p>{`Company: ${form.getFieldValue("company")}`}</p>
          <p>{`Province: ${form.getFieldValue("province")}`}</p>
          <p>{`Lat, Long: ${form.getFieldValue("latlong")}`}</p>
          <p>{`Mobile Phone: ${form.getFieldValue("mobile_phone")}`}</p>
          <p>{`Remark: ${form.getFieldValue("remark") ? form.getFieldValue("remark") : "-"}`}</p>
        </Flex>
      </ConfirmModal>
      <ConfirmModal
        title={
          <Space>
            <QuestionCircleOutlined /> Exit this page ?
          </Space>
        }
        onClickConfirmCancel={() => {
          setOpenConfirmModalState(null);
        }}
        onClickConfirmOK={() => {
          form.resetFields();
          setOpenConfirmModalState(null);
          onClose();
        }}
        open={openConfirmModalState === CreateStoreModalState.CANCEL_CREATE}
        confirmText="Confirm"
        cancelText="Cancel"
      >
        <p>Unsaved changes will be lost. Confirm exit ?</p>
      </ConfirmModal>
      {contextHolderNoti}
    </>
  );
};

export default CreateStoreModal;
