import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Space } from "antd";
import React from "react";

interface Props {
  open: boolean;
  onCancel: () => void;
}

const ModalPermissionDetail = ({ open, onCancel }: Props) => {
  // Admin | Manager | Planner | Operation | Call Center
  const dataTable = [
    {
      menu: "Delivery Order",
      items: [
        {
          name: "Upload delivery order",
          featureControl: [true, true, false, true, false],
        },
        {
          name: "Send delivery tracking",
          featureControl: [true, true, false, false, false],
        },
        {
          name: "Download (Export data)",
          featureControl: [true, true, true, true, false],
        },
        {
          name: "Delete delivery order",
          featureControl: [true, true, false, true, false],
        },
      ],
    },
    {
      menu: "Truck Monitor (Live Monitoring)",
      items: [
        {
          name: "View page",
          featureControl: [true, true, true, true, true],
        },
        {
          name: "Download (Export data)",
          featureControl: [true, true, true, true, true],
        },
        {
          name: "Change shipping Information (แก้ไขข้อมูลขนส่ง)",
          featureControl: [true, true, false, true, false],
        },
        {
          name: "Change stop sequence (แก้ไขลำดับ Stop)",
          featureControl: [true, true, false, true, false],
        },
        {
          name: "Change status (Delivery status)",
          featureControl: [true, true, false, true, false],
        },
      ],
    },
    {
      menu: "Truck Monitor (Return ETA)",
      items: [
        {
          name: "View page",
          featureControl: [true, true, true, true, false],
        },
        {
          name: "Request data",
          featureControl: [true, true, true, true, false],
        },
        {
          name: "Download (Export data)",
          featureControl: [true, true, true, true, false],
        },
      ],
    },
    {
      menu: "User Management",
      items: [
        {
          name: "View page",
          featureControl: [true, true, false, false, false],
        },
        {
          name: "Action: Create, Edit, Delete",
          featureControl: [true, false, false, false, false],
        },
      ],
    },
    {
      menu: "Store Master",
      items: [
        {
          name: "View page",
          featureControl: [true, true, true, true, true],
        },
        {
          name: "Upload Store master",
          featureControl: [true, false, false, false, false],
        },
        {
          name: "Add, Edit Store",
          featureControl: [true, true, false, false, false],
        },
        {
          name: "Delete Store",
          featureControl: [true, false, false, false, false],
        },
        {
          name: "Download (Export data)",
          featureControl: [true, true, false, false, true],
        },
        {
          name: "Action: Allow or disallow sending messages",
          featureControl: [true, false, false, false, false],
        },
      ],
    },
    {
      menu: "Messaging Status",
      items: [
        {
          name: "View page",
          featureControl: [true, true, true, true, false],
        },
        {
          name: "Action: Resend message",
          featureControl: [true, true, false, true, false],
        },
      ],
    },
    {
      menu: "Report",
      items: [
        {
          name: "Download (Export data)",
          featureControl: [true, true, true, true, false],
        },
      ],
    },
    {
      menu: "PoD Report",
      items: [
        {
          name: "View page",
          featureControl: [true, true, true, true, false],
        },
        {
          name: "Download (Export data)",
          featureControl: [true, true, true, true, false],
        },
        {
          name: "Action: Mark as unread",
          featureControl: [true, true, true, true, false],
        },
        {
          name: "PoD inspection (การตรวจสอบรายงาน)",
          featureControl: [true, true, true, true, false],
        },
      ],
    },
  ];

  return (
    <>
      <Modal
        centered
        className="modal-create-user"
        open={open}
        onCancel={() => {
          onCancel();
        }}
        title={
          <Space>
            <InfoCircleOutlined />
            <h3>รายละเอียดสิทธิ์การเข้าถึง</h3>
          </Space>
        }
        width={1200}
        footer={null}
      >
        <table className="permission-detail">
          <thead>
            <tr>
              <th>Menu</th>
              <th>Features</th>
              <th>Admin</th>
              <th>Supervisor, Manager</th>
              <th>Fleet Management, Planner</th>
              <th>Staff, Operation</th>
              <th>Call Center</th>
            </tr>
          </thead>
          <tbody>
            {dataTable.map((data) => (
              <React.Fragment key={data.menu}>
                <tr>
                  <td className="menu" rowSpan={data.items.length}>
                    {data.menu}
                  </td>
                  <td>{data.items[0].name}</td>
                  {data.items[0].featureControl.map((item, index) => (
                    <td key={index}>{item ? <CheckOutlined /> : "-"}</td>
                  ))}
                </tr>
                {data.items.map((item, index) => (
                  <React.Fragment key={item.name}>
                    {index > 0 && (
                      <tr>
                        <td>{item.name}</td>
                        {item.featureControl.map((item, index) => (
                          <td key={index}>{item ? <CheckOutlined /> : "-"}</td>
                        ))}
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default ModalPermissionDetail;
