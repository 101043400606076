import { Company } from "../type/Company";
import { UserAuthInfo } from "../type/UserAuthInfo";

const setConfigToggleTruckMonitor = (toggles: { isShowMap: boolean; isShowDashborad: boolean }) => {
  localStorage.setItem("toggleTruckMonitor", JSON.stringify(toggles));
};

const getConfigToggleTruckMonitor = (): { isShowMap: boolean; isShowDashborad: boolean } => {
  const toggleTruckMonitor = localStorage.getItem("toggleTruckMonitor");
  if (toggleTruckMonitor) {
    return JSON.parse(toggleTruckMonitor);
  }
  return { isShowMap: false, isShowDashborad: false };
};

const setCompany = (company: Company) => {
  localStorage.setItem("company", company);
};

const getCompany = (): Company | null => {
  const company = localStorage.getItem("company");
  if (company) {
    return company as Company;
  }
  return null;
};

const setUserAuthInfo = (userAuthInfo: UserAuthInfo) => {
  localStorage.setItem("userAuthInfo", JSON.stringify(userAuthInfo));
};

const getUserAuthInfo = (): UserAuthInfo | null => {
  const userAuthInfo = localStorage.getItem("userAuthInfo");

  if (userAuthInfo) {
    return JSON.parse(userAuthInfo);
  }
  return null;
};

const getCurrentRole = (): string => {
  const userAuthInfo = getUserAuthInfo();
  const role = userAuthInfo?.role[getCompany() || ""];
  return role;
};

const setTokens = (tokens: { accessToken: string; tokenExpire: number }) => {
  localStorage.setItem("accessToken", tokens.accessToken);
  localStorage.setItem("tokenExpire", tokens.tokenExpire.toString());
};

const getTokens = (): { accessToken: string; tokenExpire: number } | null => {
  const accessToken = localStorage.getItem("accessToken");
  const tokenExpireStr = localStorage.getItem("tokenExpire");
  if (accessToken && tokenExpireStr) {
    return { accessToken, tokenExpire: +tokenExpireStr };
  }
  return null;
};

const clear = () => {
  localStorage.clear();
};

const setConfigToggleDropMonitoring = (toggles: { isShowMap: boolean }) => {
  localStorage.setItem("toggleDropMonitoring", JSON.stringify(toggles));
};

const getConfigToggleDropMonitoring = (): { isShowMap: boolean } => {
  const toggleDropMonitoring = localStorage.getItem("toggleDropMonitoring");
  if (toggleDropMonitoring) {
    return JSON.parse(toggleDropMonitoring);
  }
  return { isShowMap: false };
};

const getExternalCurrentRole = (): string => {
  const userAuthInfo = getUserAuthInfo();
  const role = userAuthInfo?.role;
  return role;
};

const setConfigExternal = (external: boolean) => {
  localStorage.setItem("external", external.toString());
};

const getConfigExternal = (): boolean => {
  const external = localStorage.getItem("external");

  if (external) {
    return JSON.parse(external);
  }
  return false;
};

const setConfigToggleSider = (toggles: { isSiderCollapse: boolean }) => {
  localStorage.setItem("toggleSider", JSON.stringify(toggles));
};

const getConfigToggleSider = (): boolean => {
  const toggleSideMenu = localStorage.getItem("toggleSider");

  if (toggleSideMenu) {
    const json = JSON.parse(toggleSideMenu);
    return json.isSiderCollapse;
  }
  return false;
};

const localStorageService = {
  setConfigToggleTruckMonitor,
  getConfigToggleTruckMonitor,
  setCompany,
  getCompany,
  setUserAuthInfo,
  getUserAuthInfo,
  getCurrentRole,
  setTokens,
  getTokens,
  clear,
  setConfigToggleDropMonitoring,
  getConfigToggleDropMonitoring,
  getExternalCurrentRole,
  setConfigExternal,
  getConfigExternal,
  setConfigToggleSider,
  getConfigToggleSider,
};

export default localStorageService;
