import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import localeTh from "dayjs/locale/th";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(customParseFormat);
dayjs.extend(buddhistEra);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1m",
    h: "1h",
    d: "1d",
    M: "1 month",
    y: "1 year",
    mm: "%dm",
    hh: "%dh",
    dd: "%dd",
    MM: "%d months",
    yy: "%d years",
  },
});

export const GetDateNow = () => {
  return dayjs().locale(localeTh).format("DD-MM-YYYY");
};

export const GetDateNowBeforeFetchNew = (format?: "DD-MM-YYYY, HH:mm:ss" | "(DD-MM-YYYY, HH:mm)") => {
  return dayjs()
    .locale(localeTh)
    .format(format ?? "DD-MM-YYYY, HH:mm:ss");
};

export const GetDateTime = (date: string) => {
  if (date) {
    return dayjs(date).locale(localeTh).format("DD-MM-YYYY, HH:mm");
  } else {
    return "N/A";
  }
};

export const GetDate = (date: string) => {
  if (date) {
    return dayjs(date).locale(localeTh).format("DD-MM-YYYY");
  } else {
    return "N/A";
  }
};

export const recentTime = (date: string, locale: "th" | "en" = "th") => {
  if (date) {
    if (locale === "en") {
      const diff = dayjs().diff(date, "seconds");
      if (diff < 59) {
        return "Just now";
      }
      return dayjs(date).fromNow();
    }
    return dayjs(date).locale(localeTh).fromNow();
  } else {
    return "N/A";
  }
};

export const GetTimestamp = () => {
  return dayjs().format();
};

export const FormatDate = (date: string, format?: "DD-MM-YYYY" | "HH:mm" | "DD-MM-YYYY HH:mm" | string) => {
  if (date) {
    if (format) {
      return dayjs(date).locale(localeTh).format(format);
    } else {
      return dayjs(date).locale(localeTh).format("DD-MM-YYYY HH:mm");
    }
  } else {
    return "N/A";
  }
};
