import { FormDs, Form, SelectDs, InputDs, ButtonDs, DatePickerDs } from "design-system";
import { dcLists } from "../../../utils/DClists";
import { Col, Flex, Space } from "antd";
import { useEffect, useState } from "react";
import { GetTimestamp } from "../../../utils/GetDateNow";
import dayjs, { Dayjs } from "dayjs";

export interface SearchParams {
  dc_code: string;
  truck_plate_number: string;
  sync_status: string;
  store_number: string;
  ldt_number: string;
  sla: string;
  truck_types: string[];
  do_type_list: string[];
  planned_arrival: string;
  task_progress: string;
  test_search_error: string | null;
  current_time: string;
}

interface SearchFilterFormProps {
  searchParamsValues: Partial<SearchParams>;
  onSearch: (values: SearchParams) => void;
}

const SearchFilterForm = ({ onSearch, searchParamsValues }: SearchFilterFormProps) => {
  const [toggleAdvanceSearch, setToggleAdvanceSearch] = useState(true);
  const [form] = Form.useForm<SearchParams>();
  const truckTypes = ["10W", "10WT", "10WCR", "6W", "6WT", "6WCR", "4WCR", "4WJ", "4WJCR", "4W"];
  const dcOptions = [
    { label: "All", value: "ALL" },
    ...dcLists().map((x) => ({ label: x.label, value: x.value })),
  ];
  const slaOptions = [
    { label: "All", value: "" },
    { label: "D+0", value: "D+0" },
    { label: "D+1", value: "D+1" },
    { label: "D+N", value: "D+N" },
  ];
  const synStatusOptions = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "N/A", value: "n/a" },
    { label: "More than 1 hour", value: "gt 60m" },
  ];
  const taskProgressOptions = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Potential Truck Turn",
      value: "potential_truck_turn",
    },
    {
      label: "Not Started",
      value: "not_started",
    },
    {
      label: "Incompleted",
      value: "incompleted",
    },
    {
      label: "1 Store Left",
      value: "eq 1 left",
    },
    {
      label: "2 Store Left",
      value: "eq 2 left",
    },
    {
      label: "More than 2 Left",
      value: "gt 2 left",
    },
  ];
  const filterProps = "children";
  const formInitialValues = {
    dc_code: "ALL",
    sla: "",
    sync_status: "",
    task_progress: "",
    truck_types: [],
    truck_plate_number: "",
    ldt_number: "",
    store_number: "",
    test_search_error: null,
  };

  useEffect(() => {
    if (searchParamsValues) {
      form.setFieldsValue(searchParamsValues);
    }
  }, [searchParamsValues]);

  const checkDate = (date: Dayjs) => {
    return (
      date &&
      (date.isAfter(dayjs().endOf("day")) || date.isBefore(dayjs().subtract(90, "day").startOf("day")))
    );
  };

  const clearFormValues = () => {
    form.resetFields();
  };

  const submitForm = () => {
    const formValues = form.getFieldsValue();
    const searchParams: SearchParams = {
      ...formInitialValues,
      ...formValues,
      current_time: GetTimestamp(),
    };
    onSearch(searchParams);
  };

  return (
    <div className="wrap-form-search-truck">
      <FormDs
        form={form}
        onFinish={submitForm}
        name="search-truck"
        autoComplete="off"
        initialValues={formInitialValues}
        labelWrap
        layout="vertical"
      >
        <Flex style={{ width: "100%" }} wrap gap={8}>
          <Form.Item label="Distribution Center" name="dc_code" style={{ flex: 1 }}>
            <SelectDs
              showSearch
              data-testid="search-dc"
              listHeight={300}
              optionFilterProp={filterProps}
              options={dcOptions}
              placeholder="Select DC"
            ></SelectDs>
          </Form.Item>

          <Form.Item label="Truck Type" name="truck_types" style={{ flex: 1 }}>
            <SelectDs
              mode="multiple"
              options={truckTypes.map((type) => ({ label: type, value: type }))}
              showSearch
              data-testid="search-truck_type"
              maxTagCount={2}
              placeholder="Select Truck Type"
            />
          </Form.Item>

          <Form.Item label="Delivery SLA" name="sla" style={{ flex: 1 }}>
            <SelectDs
              showSearch
              data-testid="search-sla"
              optionFilterProp={filterProps}
              options={slaOptions}
              placeholder="Select Delivery SLA"
            ></SelectDs>
          </Form.Item>

          <Form.Item label="Task Progress" name="task_progress" style={{ flex: 1 }}>
            <SelectDs
              showSearch
              data-testid="search-task_progress"
              optionFilterProp={filterProps}
              options={taskProgressOptions}
              placeholder="Select Task Progress"
            ></SelectDs>
          </Form.Item>

          <Form.Item label="Arrival Date" name="planned_arrival" style={{ flex: 1 }}>
            <DatePickerDs placeholder="Select Date" format={"DD-MM-YYYY"} disabledDate={checkDate} />
          </Form.Item>
        </Flex>

        {toggleAdvanceSearch && (
          <Flex style={{ width: "100%" }} wrap gap={8}>
            <Form.Item label="LDT No." name="ldt_number" style={{ flex: 1 }}>
              <InputDs
                onChange={(e) => {
                  form.setFieldValue("ldt_number", e.target.value.trim());
                }}
                placeholder="Search LDT No."
              />
            </Form.Item>

            <Form.Item label="Truck Plate" name="truck_plate_number" style={{ flex: 1 }}>
              <InputDs
                onChange={(e) => {
                  form.setFieldValue("truck_plate_number", e.target.value.trim());
                }}
                placeholder="Search Truck Plate"
              />
            </Form.Item>

            <Form.Item label="Store Number" name="store_number" style={{ flex: 1 }}>
              <InputDs
                onChange={(e) => {
                  form.setFieldValue("store_number", e.target.value.trim());
                }}
                placeholder="Search Store Number"
              />
            </Form.Item>

            <Form.Item label="Sync Status" name="sync_status" style={{ flex: 1 }}>
              <SelectDs
                showSearch
                data-testid="search-sync_status"
                optionFilterProp={filterProps}
                options={synStatusOptions}
                placeholder="Select Sync Status"
              ></SelectDs>
            </Form.Item>
            <div style={{ flex: 1 }}></div>
          </Flex>
        )}
        <Flex style={{ width: "100%" }} justify="space-between">
          <ButtonDs onClick={() => setToggleAdvanceSearch(!toggleAdvanceSearch)} type="text">
            {toggleAdvanceSearch ? "Collapse" : "Advance Search"}
          </ButtonDs>
          <Space size={8}>
            <Col>
              <ButtonDs type="default" onClick={clearFormValues}>
                Clear Filter
              </ButtonDs>
            </Col>
            <Col>
              <ButtonDs type="primary" htmlType="submit">
                Search
              </ButtonDs>
            </Col>
          </Space>
        </Flex>
      </FormDs>
    </div>
  );
};

export default SearchFilterForm;
