import { ButtonDs } from "design-system";
import "../style.scss";
import { Card, Col, Collapse, CollapseProps, Flex, notification, Row, Space, Spin } from "antd";
import { DownOutlined, ExportOutlined, UpOutlined } from "@ant-design/icons";
import { TaskETA, TaskInfo, Trip, TripInfo } from "../models/trip";
import { GetDateTime } from "../../../utils/GetDateNow";
import { TaskStatusTag } from "../../../shared/component/StatusTag";
import React, { useEffect, useState } from "react";
import ChangeStatusBox, { TaskStatus } from "./ChangeStatusBox";
import LocalstorageService from "../../../shared/service/Localstorage.service";
import { UseMutationResult } from "@tanstack/react-query";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import dayjs from "dayjs";

interface TaskListProps {
  loading: boolean;
  data: TaskInfo[];
  taskETA: TaskETA[];
  dataTripInfo: TripInfo | null;
  onClickStore: (id: string) => void;
  tripId: string;
  fetchTripDetail: UseMutationResult<Trip, Error, string, unknown>;
}

const TaskList = ({
  taskETA,
  loading,
  data,
  dataTripInfo,
  onClickStore,
  fetchTripDetail,
  tripId,
}: TaskListProps) => {
  const store_url = process.env.REACT_APP_STORE_URL_TD;
  const selectedCompany = LocalstorageService.getCompany();
  const [changeStatusBoxOpenId, setChangeStatusBoxOpenId] = useState<string>();
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const userRole = LocalstorageService.getCurrentRole();
  const [notificationComponent, contextHolderNoti] = notification.useNotification();

  const statusMap = {
    active: ["waiting", "in-transit"],
    inActive: ["success", "canceled", "exception"],
  };

  const toggleActiveStatusCollapse = () => {
    // Toogle open active (waiting, in-transit) status collapse
    const activeTaskIds = data
      .filter((item) => statusMap.active.includes(item.status.toLowerCase()))
      .map((item) => item.task_id);

    setActiveKey(activeTaskIds);
  };

  const toggleAllCollapse = ({ toggle }: { toggle: boolean }) => {
    if (toggle) {
      // Toogle open all collapse
      setActiveKey(data.map((item) => item.task_id));
    } else {
      // Toogle close all collapse
      setActiveKey([]);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      toggleActiveStatusCollapse();
    }
  }, [data]);

  const displayInfo = ({
    title,
    content,
    key,
  }: {
    title: string;
    content: string | React.ReactNode;
    key?: string;
  }) => {
    return (
      <Row wrap={false} className="info" data-testid={`task-detail-${key}`}>
        <Col flex={"100px"} style={{ display: "flex", alignItems: "start" }}>
          <span className="title">{title}&nbsp;:&nbsp;</span>
        </Col>
        <Col flex="auto">
          <span className="content truncate">{content}</span>
        </Col>
      </Row>
    );
  };

  const CardTitle = () => {
    return (
      <Flex align="center" justify="space-between">
        <span className="title">Task</span>
        <Space>
          <ButtonDs size="middle" type="text" onClick={() => toggleActiveStatusCollapse()}>
            Expand only incomplete
          </ButtonDs>
          <ButtonDs size="middle" type="text" onClick={() => toggleAllCollapse({ toggle: true })}>
            Expand
          </ButtonDs>
          <ButtonDs size="middle" type="text" onClick={() => toggleAllCollapse({ toggle: false })}>
            Collapse
          </ButtonDs>
        </Space>
      </Flex>
    );
  };

  const displayTitle = ({
    storeName,
    storeNumber,
    stop,
    province,
    status,
  }: {
    storeName: string;
    storeNumber: string;
    stop: string;
    province: string;
    status: string;
  }) => {
    return (
      <Flex style={{ width: "100%" }}>
        <div className="stop-no">
          <span>{stop}</span>
        </div>
        <Flex style={{ flex: 1, position: "relative", overflow: "hidden" }}>
          <Flex style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
            <div className="store" onClick={() => onClickStore(storeNumber)}>
              <span>
                {storeNumber} {storeName}
              </span>
            </div>
            <div className="province">
              <span>{province}</span>
            </div>
          </Flex>
        </Flex>
        <TaskStatusTag status={status} />
      </Flex>
    );
  };

  const openNotiUpdateStatusSuccess = ({ status }: { status: string }) => {
    NotificationComponent({
      notification: notificationComponent,
      type: "success",
      topic: "Status has updated",
      message: "The status has update to " + status,
    });
  };

  const openNotiUpdateStatusFailed = () => {
    NotificationComponent({
      notification: notificationComponent,
      type: "error",
      topic: "An error occurred",
      message: "Please try again",
    });
  };

  const onChangeStatusSuccess = ({ newStatus }: { newStatus: string }) => {
    setChangeStatusBoxOpenId(undefined);
    openNotiUpdateStatusSuccess({ status: newStatus });
    fetchTripDetail.mutate(tripId);
  };

  const onChangeStatusError = () => {
    openNotiUpdateStatusFailed();
  };

  const collapseItem = ({ task }: { task: TaskInfo }) => {
    const onClickStoreTracking = () => {
      window.open(
        `${store_url}/t/${
          task.tracking_number_uuid === "" ? task.tracking_number : task.tracking_number_uuid
        }?ct=y&company=${selectedCompany}`,
        "_blank",
        "noopener",
      );
    };

    const onClickSeeReport = ({ storeNumber }: { storeNumber: string }) => {
      const tripId = dataTripInfo ? dataTripInfo.trip_uuid : "";
      window.open(`/report-detail/${tripId}/${storeNumber}`, "_blank");
    };

    const openMapLink = ({ stop }: { stop: string }) => {
      const driverLocation = dataTripInfo?.truck_location;
      const dcLocation = dataTripInfo?.dc_location;
      const origin = driverLocation
        ? `${driverLocation.lat},${driverLocation.long}`
        : `${dcLocation?.lat},${dcLocation?.long}`;
      const waypointsList = data
        .slice(0, data.findIndex((item) => item.stop === stop) + 1)
        .map((item) => `${item.lat},${item.long}`);

      const waypoints = waypointsList.join("/");
      const test = `https://www.google.com/maps/dir/${origin}/${waypoints}`;

      window.open(test, "_blank", "noopener");
    };

    const disabledActionButton: boolean = userRole === "planner" || userRole === "call_center";

    const mapStatus: { [key: string]: TaskStatus } = {
      waiting: TaskStatus.WAITING,
      "in-transit": TaskStatus.IN_TRANSIT,
      success: TaskStatus.SUCCESS,
      canceled: TaskStatus.CANCELED,
      exception: TaskStatus.EXCEPTION,
    };

    const isChangeStatusBoxOpen = changeStatusBoxOpenId === task.task_id;

    const displayEtaContent = (taskId: string, status: string) => {
      if (statusMap.active.includes(status.toLowerCase())) {
        const eta = taskETA.find((item) => item.task_id === taskId);
        if (eta && eta.eta_start && eta.eta_end) {
          const startDateTime = dayjs(eta.eta_start).locale("th").format("DD-MM-YYYY, HH:mm");
          const endTime = dayjs(eta.eta_end).locale("th").format("HH:mm");
          return `${startDateTime} - ${endTime}`;
        }
        return "";
      }
      return "";
    };

    const items: CollapseProps["items"] = [
      {
        key: task.task_id,
        label: (
          <Flex vertical gap={8}>
            {displayTitle({
              stop: task.stop,
              storeNumber: task.store_number,
              storeName: task.store_name,
              province: task.province,
              status: task.status,
            })}
            <Flex align="center">
              <Row wrap={false} className="info" style={{ width: "100%" }}>
                <Col flex={"100px"} style={{ display: "flex", alignItems: "center" }}>
                  <span className="title">{"Last Update"}&nbsp;:&nbsp;</span>
                </Col>
                <Col flex="auto">
                  <Flex align="center" gap={8} justify="space-between">
                    <span className="content">{GetDateTime(task.last_update)}</span>
                    {(task.status === "waiting" || task.status === "in-transit") && (
                      <ButtonDs
                        size="middle"
                        onClick={() => openMapLink({ stop: task.stop })}
                        data-testid={`check-destination-button-${task.stop}`}
                      >
                        Check Destination
                      </ButtonDs>
                    )}
                  </Flex>
                </Col>
              </Row>
            </Flex>
          </Flex>
        ),
        children: (
          <div className="task-list-content">
            <Flex vertical gap={16}>
              <Flex vertical gap={8} className="info">
                {displayInfo({
                  key: `do-number-${task.stop}`,
                  title: "Do No",
                  content: task.do_numbers.join(", "),
                })}
                {displayInfo({
                  title: "Tracking",
                  content: (
                    <Space align="center">
                      {task.tracking_number}
                      {task.tracking_number && (
                        <div
                          className="tracking-icon-button"
                          onClick={onClickStoreTracking}
                          data-testid={`tracking-button-${task.stop}`}
                        >
                          <ExportOutlined />
                        </div>
                      )}
                    </Space>
                  ),
                })}
                {displayInfo({
                  key: `eta-${task.stop}`,
                  title: "ETA",
                  content: displayEtaContent(task.task_id, task.status),
                })}
              </Flex>
              <Space>
                <ButtonDs
                  size="middle"
                  type="primary"
                  onClick={() => onClickSeeReport({ storeNumber: task.store_number })}
                  data-testid={`see-report-button-${task.stop}`}
                >
                  <ExportOutlined />
                  See Report
                </ButtonDs>
                {!isChangeStatusBoxOpen && (
                  <ButtonDs
                    size="middle"
                    onClick={() => {
                      setChangeStatusBoxOpenId(task.task_id);
                    }}
                    disabled={disabledActionButton}
                    data-testid={`change-status-button-${task.stop}`}
                  >
                    Change Status
                  </ButtonDs>
                )}
              </Space>
              {isChangeStatusBoxOpen && (
                <ChangeStatusBox
                  taskId={task.task_id}
                  currentStatus={mapStatus[task.status]}
                  onCancel={() => {
                    setChangeStatusBoxOpenId(undefined);
                  }}
                  onSubmitSuccess={onChangeStatusSuccess}
                  onSubmitFail={onChangeStatusError}
                />
              )}
            </Flex>
          </div>
        ),
      },
    ];

    return items;
  };

  return loading ? (
    <Card className="drawer-card" styles={{ body: { height: "100%", width: "100%" } }}>
      <Flex align="center" justify="center" style={{ width: "100%", height: "100%" }}>
        <Spin></Spin>
      </Flex>
    </Card>
  ) : (
    <Card
      className="drawer-card"
      title={CardTitle()}
      styles={{ header: { borderBottom: "none", paddingTop: 0 }, body: { paddingTop: 0 } }}
    >
      <Flex className="task-list" align="center" gap={16} vertical>
        {data.map((item: TaskInfo) => {
          return (
            <div className="task-list-card" key={item.task_id} data-testid={`task-card-${item.stop}`}>
              <Collapse
                className="task-list-collapse"
                activeKey={activeKey}
                defaultActiveKey={activeKey}
                items={collapseItem({ task: item })}
                style={{ width: "100%" }}
                expandIconPosition="start"
                onChange={(key) => {
                  setActiveKey(key as string[]);
                }}
                expandIcon={({ isActive }) => (
                  <span
                    className="collapse-icon"
                    style={{
                      transform: isActive ? "rotate(0deg)" : "rotate(180deg)",
                      transition: "transform 0.4s ease-in-out",
                    }}
                    data-testid={`collapse-icon-${item.stop}`}
                  >
                    {isActive ? <UpOutlined /> : <DownOutlined style={{ transform: "rotate(180deg)" }} />}
                  </span>
                )}
                ghost
                collapsible="icon"
              />
            </div>
          );
        })}
      </Flex>
      {contextHolderNoti}
    </Card>
  );
};

export default TaskList;
