import "./style.scss";
import { DeleteOutlined, DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { Col, Row, UploadProps, Upload, Space } from "antd";
import { ButtonDs } from "design-system";
import DesignSystemTheme from "./DesignSystemTheme";
import { UploadFile } from "antd/lib/upload/interface";
import AlertMessage from "./AlertMessage";

interface Props {
  contentText?: string;
  descriptionText?: string;
  draggerProps: UploadProps;
  fileUpload: UploadFile[];
  fileTemplateHref?: string;
  errorMessage?: { title: string; description: string } | null;
  onSubmit?: () => void;
  isLoading?: boolean;
}
const DraggerFileComponent = ({
  contentText,
  descriptionText,
  draggerProps,
  fileUpload,
  fileTemplateHref,
  onSubmit,
  errorMessage,
  isLoading,
}: Props) => {
  const { Dragger } = Upload;

  return (
    <DesignSystemTheme>
      <Row>
        <Col span={24}>
          <div className="upload-file">
            <div className="text-caption">
              File Upload : <span style={{ color: "red" }}>*</span>
            </div>
            {errorMessage && (
              <div style={{ marginBottom: 16 }}>
                <AlertMessage
                  type="error"
                  message={errorMessage.title}
                  description={errorMessage.description}
                  margin="0 0 16px 0"
                />
              </div>
            )}
            {fileUpload.length > 0 &&
              fileUpload.map((file) => (
                <Space className="file-uploaded" key={file.uid}>
                  <p>File Uploaded : {file.name}</p>
                  <DeleteOutlined className="delete-icon" onClick={() => draggerProps.onRemove?.(file)} />
                </Space>
              ))}
            {((fileUpload.length < 1 && draggerProps.multiple === false) ||
              draggerProps.multiple === true) && (
              <Dragger className="dragger-file-box" {...draggerProps}>
                <div>
                  <p className="dragger-icon">
                    <InboxOutlined />
                  </p>
                  <p className="dragger-title">
                    {contentText ?? "Click or drag file to this area for upload"}
                  </p>
                  <div className="dragger-description">
                    {descriptionText ?? "File support .xlsx only (Size not more than 100 MB.)"}
                  </div>
                </div>
              </Dragger>
            )}
          </div>
        </Col>
        <Col span={24}>
          <div className="submit-section">
            <div>
              <span>Template File Example : </span>

              <a className="template-file-button" href={fileTemplateHref}>
                <Space>
                  <DownloadOutlined />
                  Template File
                </Space>
              </a>
            </div>
            <ButtonDs type="primary" disabled={fileUpload.length < 1} onClick={onSubmit} loading={isLoading}>
              Submit
            </ButtonDs>
          </div>
        </Col>
      </Row>
    </DesignSystemTheme>
  );
};

export default DraggerFileComponent;
