import "./style.scss";

interface LoadingScreenComponentProps {
  loading: boolean;
}
const LoadingScreenComponent = ({ loading = false }: LoadingScreenComponentProps) => {
  return (
    loading && (
      <div className="baomove-loading-container">
        <img className="loading-animate" src="/baomove-animate.gif" alt="baomove-loading" />
      </div>
    )
  );
};

export default LoadingScreenComponent;
