import { Flex } from "antd";
import { TableDs } from "design-system";
import { Driver } from "../models/driver";
import { ColumnsType } from "antd/es/table";
import LocalstorageService from "../../../shared/service/Localstorage.service";
// import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { recentTime } from "../../../utils/GetDateNow";
import { ButtonDs } from "design-system";

interface DriverListTableProps {
  loading: boolean;
  driverDatas: Driver[];
  isDashboardActive: boolean;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  toggleMap: (value: boolean) => void;
  setPopupDriverKey: (value: Driver) => void;
  onOpenTruckDetail: (value: string) => void;
}
const DriverListTable = ({
  loading,
  driverDatas,
  isDashboardActive,
  currentPage,
  setCurrentPage,
  toggleMap,
  setPopupDriverKey,
  onOpenTruckDetail,
}: DriverListTableProps) => {
  // const navigate = useNavigate();
  const pageSizeRef = useRef(10);

  const columns: ColumnsType<Driver> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 15,
      align: "center",
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: "Truck Plate",
      dataIndex: "truck_plate_number",
      key: "truck_plate_number",
      render: (_, record) => (
        <>
          {record.location?.lat && record.location?.long ? (
            <a
              style={{ color: "#000", textDecoration: "underline" }}
              onClick={() => {
                toggleMap(true);
                LocalstorageService.setConfigToggleTruckMonitor({
                  isShowDashborad: isDashboardActive,
                  isShowMap: true,
                });
                const element = document.getElementById("totom-map");
                setPopupDriverKey(record);
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              {record.truck_plate_number}
            </a>
          ) : (
            record.truck_plate_number
          )}
        </>
      ),
    },
    {
      title: "Truck Type",
      dataIndex: "truck_type",
      key: "truck_type",
    },
    {
      title: "DC",
      dataIndex: "dc_code",
      key: "dc_code",
    },
    {
      title: "LDT No.",
      dataIndex: "ldt_number",
      key: "ldt_number",
      render: (_, record) => {
        return <>{record.ldt_number ? record.ldt_number : "-"}</>;
      },
    },
    {
      title: "SLA",
      dataIndex: "sla",
      key: "sla",
    },
    {
      title: "Plan Load",
      dataIndex: "planned_load",
      render: (__, record) => record.planned_date + ", " + record.planned_time,
    },
    {
      title: "Complete",
      dataIndex: "task_complete",
      render: (_, record) => (
        <div data-testid="transport-detail-task-complete">{`${record.completed_job}/${record.total_job}`}</div>
      ),
      align: "center",
    },
    {
      title: "Arrival Date",
      dataIndex: "arrival_date",
      key: "arrival_date",
    },
    {
      title: "Mobile Phone",
      dataIndex: "mobilePhone",
      key: "mobilePhone",
      render: (_, record) => (
        <a className="phone-number" href={`tel:${record.driver_mobile_number}`}>
          {record.driver_mobile_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
        </a>
      ),
    },
    {
      title: "Sync Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Flex vertical justify="center" align="center">
          <span className={`status-badge ${record.status ? "complete" : "incomplete"}`}></span>
          <div>{recentTime(record.last_location_timestamp, "en")}</div>
        </Flex>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <ButtonDs
          type="default"
          onClick={() => {
            // navigate(`/gps-tracking/${record.tracking_driver_id}`);
            onOpenTruckDetail(record.trip_id);
          }}
          data-testid="trip-detail-button"
        >
          Detail
        </ButtonDs>
        // <Link className="action-gps-tracking" to={`/gps-tracking/${record.tracking_driver_id}`}>
        //   Detail
        // </Link>
      ),
    },
  ];

  return (
    <>
      <TableDs
        scroll={{ x: 1000 }}
        loading={loading}
        dataSource={driverDatas}
        columns={columns}
        pagination={{
          showSizeChanger: true,
          current: currentPage,
          defaultPageSize: pageSizeRef.current,
          onChange: (newCurrent, newPageSize) => {
            const pageSizeChange = pageSizeRef.current !== newPageSize;
            if (pageSizeChange) {
              setCurrentPage(1);
            } else {
              setCurrentPage(newCurrent);
            }
            pageSizeRef.current = newPageSize;
          },
        }}
        rowKey={(record) => record.tracking_driver_id}
      />
    </>
  );
};

export default DriverListTable;
