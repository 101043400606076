import ApiClient from "../../../shared/api/ApiClient";
import { DASHBOARD, DOWNLOAD_TRIPS_V2, SEARCH_DRIVER } from "../../../shared/api/ApiEndPoint";
import { ChangeStatusForm } from "../components/ChangeStatusBox";
import { SearchParams } from "../components/SearchFilterForm";
import { Dashboard, Driver } from "../models/driver";
import { RouteHistory } from "../models/route";
import { TaskETA, Trip } from "../models/trip";

const TRIP_DETAIL = "v2/trips/:trip_id";
const GET_ROUTE_HISTORY = "/v2/trips/:tracking_id/location-history";
const UPDATE_TASK_STATUS = "/v2/tasks/:task_id/status";
const GET_CAL_ETA = "/v2/trips/:trip_id/eta/calculate";

const searchDriver = async (params: Partial<SearchParams>): Promise<Driver[]> => {
  let headers = {};
  if (params.test_search_error !== null) {
    headers = {
      "X-Test-Error-Flag": `test_search_error=${params.test_search_error}`,
    };
  }
  return ApiClient.post<Driver[]>(SEARCH_DRIVER, params, { headers }).then((res) => res.data);
};

const listDashboard = async (params: Partial<SearchParams>): Promise<Dashboard> => {
  let headers = {};
  if (params.test_search_error !== null) {
    headers = {
      "X-Test-Error-Flag": `test_search_error=${params.test_search_error}`,
    };
  }

  return ApiClient.post<Dashboard>(DASHBOARD, params, {
    headers: headers,
  }).then((res) => res.data);
};

const downloadTrips = async (params: Partial<SearchParams>) => {
  const response = await ApiClient.post(DOWNLOAD_TRIPS_V2, { ...params }, { responseType: "blob" });
  const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = URL.createObjectURL(response.data);
  link.setAttribute("download", decodeURI(filename));
  link.click();
  return null;
};

const getTripDetail = async (tripId: string) =>
  ApiClient.get<Trip>(TRIP_DETAIL.replace(":trip_id", tripId)).then((res) => res.data);

const updateTaskStatus = async (taskId: string, updateStatusData: ChangeStatusForm) =>
  ApiClient.put(UPDATE_TASK_STATUS.replace(":task_id", taskId), updateStatusData).then((res) => res.data);

const getRouteHistory = async (trackingId: string) =>
  ApiClient.get<RouteHistory>(GET_ROUTE_HISTORY.replace(":tracking_id", trackingId)).then((res) => {
    const devices = res.data.devices;
    if (devices !== undefined && devices.length > 0) {
      return devices;
    }
    return [];
  });

const getTripETA = async (tripId: string) =>
  ApiClient.get<TaskETA[]>(GET_CAL_ETA.replace(":trip_id", tripId)).then((res) => res.data);

const liveMonitoringService = {
  searchDriver,
  listDashboard,
  downloadTrips,
  getTripDetail,
  updateTaskStatus,
  getRouteHistory,
  getTripETA,
};

export default liveMonitoringService;
