import "./style.scss";
import { Card, Col, Layout, Row, Upload, UploadProps, notification } from "antd";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import { DownloadOutlined } from "@ant-design/icons";
import DraggerFileComponent from "../../shared/component/DraggerFileComponent";
import FormSearch from "./components/FormSearch";
import DataTable from "./components/DataTable";
import StoreMasterService from "./services/store-master-service";
import { useEffect, useRef, useState } from "react";
import {
  CellErrorResponse,
  SearchStoreMasterParams,
  StoreMasterParams,
  StoreMasterType,
  StoreType,
  UploadStoreMasterResponse,
} from "./models/store";
import { ButtonDs } from "design-system";
import { UploadFile } from "antd/es/upload/interface";
import TooltipComponent from "../../shared/component/TooltipComponent";
import { NotificationComponent } from "../../shared/component/NotificationComponent";
import AlertMessage from "../../shared/component/AlertMessage";
import LocalstorageService from "../../shared/service/Localstorage.service";
import React from "react";
import storeMasterService from "./services/store-master-service";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

const { Content } = Layout;

const NewStoreMaster = () => {
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const userRole = LocalstorageService.getCurrentRole();
  const [dataSource, setDataSource] = useState<StoreMasterType>();
  const [fileUploaded, setFileUploaded] = useState<UploadFile[]>([]);
  const [searchStoreMaster, setSearchStoreMaster] = useState<SearchStoreMasterParams>({
    cursor: null,
    limit: null,
    company: "",
    province: "",
    store_name: "",
    store_number: "",
    enabled_notification: null,
    is_registered: null,
    enabled_checking_geofence: null,
  });
  const [errorMessageFileUpload, setErrorMessageFileUpload] = useState<{
    title: string;
    description: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionNotiFail, setIsActionNotiFail] = useState(false);
  const [isDownloadindFile, setIsDownloadingFile] = useState(false);
  const updatedStoreRef = useRef<StoreType | null>(null);

  useEffect(() => {
    const subscription = StoreMasterService.signalNotiFail.subscribe((loading) =>
      setIsActionNotiFail(loading),
    );
    const subscriptionUpdate = storeMasterService.storeUpdated.subscribe((updatedStore) => {
      if (updatedStore) {
        NotificationComponent({
          notification: notificationComponent,
          type: "success",
          message: "Store information updated successfully",
        });
        updatedStoreRef.current = updatedStore;
      }
    });
    const subscriptionDelete = storeMasterService.signalDeleteStore.subscribe((isDelete) => {
      if (isDelete) {
        NotificationComponent({
          notification: notificationComponent,
          type: "success",
          message: "Store deleted successfully",
        });
      }
    });

    initialData();

    return () => {
      subscription.unsubscribe();
      subscriptionUpdate.unsubscribe();
      subscriptionDelete.unsubscribe();
      StoreMasterService.signalNotiFail.next(false);
      storeMasterService.storeUpdated.next(null);
      storeMasterService.signalDeleteStore.next(false);
      updatedStoreRef.current = null;
    };
  }, []);

  const openNotificationSuccess = ({
    topic,
    message,
  }: {
    topic?: string;
    message: string | React.ReactNode;
  }) => {
    NotificationComponent({
      notification: notificationComponent,
      type: "success",
      topic: topic || "File Uploaded Successfully",
      message: message,
    });
  };

  const openNotificationError = ({
    topic,
    message,
  }: {
    topic?: string;
    message: string | React.ReactNode;
  }) => {
    NotificationComponent({
      notification: notificationComponent,
      type: "error",
      topic: topic || "File upload failed",
      message: message,
    });
  };

  const initialData = async () => {
    StoreMasterService.isloadingTable.next(true);
    const searchStoreMasterParam: SearchStoreMasterParams = {
      cursor: null,
      limit: 150,
      company: "",
      province: "",
      store_name: "",
      store_number: "",
      enabled_notification: null,
      is_registered: null,
      enabled_checking_geofence: null,
    };
    await StoreMasterService.searchStoreMaster(searchStoreMasterParam).then((res: StoreMasterType) => {
      const updatedStore = updatedStoreRef.current;
      if (updatedStore) {
        const updatedStoreIndex = res.data.findIndex(
          (store) => store.store_number === updatedStore.store_number,
        );
        if (updatedStoreIndex !== -1) {
          res.data.splice(updatedStoreIndex, 1);
        }
        res.data = [updatedStore, ...res.data];
        updatedStoreRef.current = null;
      }
      setDataSource(res);
    });
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    customRequest: ({ onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 500);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading" && status !== "error") {
        setFileUploaded(info.fileList);
      }
    },
    beforeUpload: (file: any) => {
      const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        setErrorMessageFileUpload({
          title: "File upload failed",
          description: "Please upload an .xlsx file only",
        });
      } else {
        setErrorMessageFileUpload(null);
      }
      return isXlsx || Upload.LIST_IGNORE;
    },
    onRemove(file: UploadFile) {
      const index = fileUploaded.indexOf(file);
      if (index > -1) {
        const recentList = fileUploaded.filter((_, fIdx) => fIdx !== index);
        setFileUploaded(recentList);
      }
    },
  };

  const errorMessageMap: { [key: string]: string } = {
    upload_store_master_fail: "Cannot open Excel",
    invalid_company: "Invalid company",
    invalid_store_number: "Invalid store number",
    invalid_store_number_duplicate: "Invalid store number (duplicate)",
    invalid_store_name: "Invalid store name",
    invalid_location: "Invalid lat, long",
    invalid_mobile_phone: "Invalid mobile phone",
    invalid_store_master_file_header: "Invalid header",
  };

  const cellErrorMessage = (cells: CellErrorResponse[]): React.ReactNode => {
    return cells.map((cell: CellErrorResponse, index: number) => {
      const error = errorMessageMap[cell.code];
      return (
        <React.Fragment key={index}>
          <li>
            {error || "Invalid"} row {cell.row} column {cell.col}
          </li>
        </React.Fragment>
      );
    });
  };

  const uploadStoreMasterMutation = useMutation({
    mutationFn: StoreMasterService.uploadStoreMaster,
    onSuccess: () => {
      const fileName = fileUploaded[0].name;
      openNotificationSuccess({
        message: `Uploaded file: ${fileName}`,
      });
      initialData();
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const err: UploadStoreMasterResponse = error.response.data as UploadStoreMasterResponse;
        const responseErrorCode = err.code;
        const reponseErrorMessage = err.message;
        const cellErrorResponse = err.cells;

        if (cellErrorResponse) {
          openNotificationError({
            message: cellErrorMessage(cellErrorResponse),
          });
        } else {
          openNotificationError({
            message: errorMessageMap[responseErrorCode] || reponseErrorMessage,
          });
        }
      }
    },
    onSettled: () => {
      setFileUploaded([]);
      setIsLoading(false);
      StoreMasterService.signalResetForm.next(true);
    },
    retry: 0,
  });

  const onSubmit = async () => {
    setIsLoading(true);
    uploadStoreMasterMutation.mutate(fileUploaded[0].originFileObj as File);
  };

  const stringToBooleanValue = (value: string) => {
    const stringValue = String(value).toLowerCase();

    switch (stringValue) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return undefined;
    }
  };

  const onSearchFinish = async (value: any) => {
    setIsLoading(true);
    StoreMasterService.isloadingTable.next(true);
    const company: string = value["company"];
    const province: string = value["province"];
    const storeName: string = value["store_name"];
    const storeNumber: string = value["store_number"];
    const enabledNotification: boolean | undefined = stringToBooleanValue(value["enabled_notification"]);
    const registrationStatus: boolean | undefined = stringToBooleanValue(value["registration_status"]);
    const enabled_checking_geofence: boolean | undefined = stringToBooleanValue(
      value["enabled_checking_geofence"],
    );

    const params: SearchStoreMasterParams = {
      cursor: null,
      limit: null,
      company: company ?? "",
      province: province ?? "",
      store_name: storeName ?? "",
      store_number: storeNumber ?? "",
      enabled_notification: enabledNotification ?? null,
      is_registered: registrationStatus ?? null,
      enabled_checking_geofence: enabled_checking_geofence ?? null,
    };
    setSearchStoreMaster(params);

    await StoreMasterService.searchStoreMaster(params)
      .then((res: StoreMasterType) => {
        setDataSource(res);
      })
      .catch(() => {
        setDataSource(undefined);
      })
      .finally(() => {
        setIsLoading(false);
        StoreMasterService.isloadingTable.next(false);
      });
  };

  const createFile = (fileName: string, data: Blob) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(data);
    link.setAttribute("download", decodeURI(fileName));
    link.click();
  };

  const onResetTable = () => {
    setIsActionNotiFail(false);
    setSearchStoreMaster({
      cursor: null,
      limit: null,
      company: "",
      province: "",
      store_name: "",
      store_number: "",
      enabled_notification: null,
      is_registered: null,
      enabled_checking_geofence: null,
    });
    initialData();
  };

  const onClickDownload = async () => {
    setIsDownloadingFile(true);
    const params: StoreMasterParams = {
      province: searchStoreMaster.province,
      company: searchStoreMaster.company,
      store_name: searchStoreMaster.store_name ?? "",
      store_number: searchStoreMaster.store_number ?? "",
      enabled_notification: searchStoreMaster.enabled_notification,
      is_registered: searchStoreMaster.is_registered,
      enabled_checking_geofence: searchStoreMaster.enabled_checking_geofence,
    };
    await StoreMasterService.downloadStoreMasterFile(params)
      .then(
        (res) => {
          const filename = res.headers["content-disposition"].split(";")[1].split("=")[1];
          createFile(filename, res.data);
        },
        (error) => {
          console.log("error", error);
        },
      )
      .finally(() => {
        setIsDownloadingFile(false);
      });
  };

  return (
    <DesignSystemTheme>
      <Content className="store-master-content">
        <div className="page-header" style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h1>Store Master</h1>
          <TooltipComponent title="Store Information Overview" placement="right" />
        </div>
        {userRole?.includes("admin") && (
          <Card>
            <h3>Upload File</h3>
            <DraggerFileComponent
              draggerProps={props}
              contentText="Click or drag file to this area for upload"
              descriptionText="File support .xlsx only (Size not more than 100 MB.)"
              fileUpload={fileUploaded}
              onSubmit={onSubmit}
              fileTemplateHref="/files_download/StoreMaster_Template_File_v2.0.xlsx"
              errorMessage={errorMessageFileUpload}
              isLoading={isLoading}
              data-test-id="select_file_store_master"
            />
          </Card>
        )}

        <Card style={{ marginTop: 16 }}>
          <Row gutter={16}>
            <Col span={24}>
              <div className="table-section-header">
                <h3>Store Information List</h3>
                {(userRole?.includes("admin") ||
                  userRole?.includes("manager") ||
                  userRole?.includes("call_center")) && (
                  <ButtonDs
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={onClickDownload}
                    loading={isDownloadindFile}
                    disabled={isLoading}
                  >
                    Download
                  </ButtonDs>
                )}
              </div>
              {isActionNotiFail && (
                <AlertMessage
                  type="error"
                  message="Error Occured"
                  description={`Unable to proceed at this time. Please try again`}
                  margin="24px 0 12px 0"
                />
              )}
            </Col>
            <Col span={24}>
              <FormSearch onSearch={onSearchFinish} onResetSearch={onResetTable} isLoading={isLoading} />
            </Col>
            <Col span={24} className="store-master-table">
              <DataTable
                dataSource={dataSource}
                searchParam={searchStoreMaster}
                onUpdateTable={initialData}
              />
            </Col>
          </Row>
        </Card>
        {contextHolderNoti}
      </Content>
    </DesignSystemTheme>
  );
};

export default NewStoreMaster;
