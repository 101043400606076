import { CopyOutlined, SyncOutlined } from "@ant-design/icons";
import { Card, Col, Flex, notification, Row, Spin, Tooltip } from "antd";
import { ButtonDs, Form } from "design-system";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import { TripInfo } from "../models/trip";
import { FormatDate, recentTime } from "../../../utils/GetDateNow";

interface TruckInformationProps {
  loading: boolean;
  data: TripInfo | null;
  onClickDc: (dcCode: string) => void;
  onClickTruckPlate: (truckPlateNumber: string) => void;
  onClickUpdateETA: () => void;
  isFetchingETA: boolean;
}

const TruckInformation = ({
  loading,
  data,
  onClickDc,
  onClickTruckPlate,
  onClickUpdateETA,
  isFetchingETA,
}: TruckInformationProps) => {
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const ldtNumber = window.location.pathname.split("/").pop();

  const diaplayNotification = (type: "success" | "error", message: string, topic?: string) => {
    return NotificationComponent({
      notification: notificationComponent,
      type,
      topic: topic,
      message: message,
      closeIcon: true,
    });
  };

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location}`);
      diaplayNotification("success", "URL Copied Successfully");
    } catch (err) {
      diaplayNotification("error", "URL Copied Failed");
    }
  };

  const infoItem = ({
    label,
    children,
    dataTestId,
  }: {
    label: string;
    children: React.ReactNode | string;
    dataTestId?: string;
  }) => {
    return (
      <Form.Item
        label={<span className="label">{label} :</span>}
        data-testid={dataTestId}
        layout="vertical"
        className="flex-info-item"
        style={{}}
      >
        <span className="content">{children}</span>
      </Form.Item>
    );
  };

  return (
    <>
      {loading ? (
        <Card
          className="drawer-card"
          styles={{ body: { height: "100%", width: "100%" } }}
          style={{ height: 358 }}
        >
          <Flex align="center" justify="center" style={{ width: "100%", height: "100%" }}>
            <Spin></Spin>
          </Flex>
        </Card>
      ) : (
        <Card
          title={
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <span className="title">Truck Information</span>
                <Tooltip
                  overlayInnerStyle={{ color: "black" }}
                  placement="top"
                  color="white"
                  title="Copy URL"
                >
                  <ButtonDs
                    style={{ padding: "7px 15px", fontSize: 16, borderRadius: 8, minWidth: "40px" }}
                    onClick={() => copyToClipBoard()}
                    type="link"
                    icon={<CopyOutlined size={16} />}
                    data-testid="truck-detail-copy-url-button"
                  />
                </Tooltip>
              </Flex>
              <Flex align="center" className="title-link">
                {data?.truck_location ? (
                  <a onClick={() => onClickTruckPlate(data?.truck_plate_number || "")}>
                    <u>{data?.truck_plate_number}</u>
                  </a>
                ) : (
                  <span>{data?.truck_plate_number}</span>
                )}
                <div className="description" data-testid="truck-detail-truck-type">
                  &nbsp;-&nbsp;{data?.truck_type}
                </div>
              </Flex>
            </Flex>
          }
          className="drawer-card"
          styles={{ header: { borderBottom: "none", paddingTop: 0 }, body: { paddingTop: 0 } }}
        >
          <Flex align="center" gap={16} justify="space-between">
            <Flex align="center" wrap gap={4}>
              <span className={`status-badge ${data?.active ? "complete" : "incomplete"}`}></span>
              <span>{data?.active ? "Active" : "Inactive"}</span>
              <span className="description">({recentTime(data?.last_location_timestamp || "", "en")})</span>
            </Flex>
            <ButtonDs
              autoFocus={false}
              onClick={onClickUpdateETA}
              icon={<SyncOutlined />}
              loading={isFetchingETA}
            >
              Update ETA
            </ButtonDs>
          </Flex>
          <div style={{ marginTop: 16 }}>
            <Flex wrap>
              {infoItem({
                label: "LDT No",
                children: ldtNumber,
                dataTestId: "truck-detail-ldt-no",
              })}
              {infoItem({
                label: "Contact driver",
                children: data?.driver_mobile_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
                dataTestId: "truck-detail-driver-mobile-number",
              })}
              {infoItem({
                label: "Driver name",
                children: data?.driver_name,
                dataTestId: "truck-detail-driver-name",
              })}
              {infoItem({
                label: "Transporter",
                children: data?.transportation_name,
                dataTestId: "truck-detail-transporter",
              })}
              {infoItem({
                label: "Planned Date",
                children: FormatDate(data?.planned_date || "", "DD-MM-YYYY"),
                dataTestId: "truck-detail-planned-date",
              })}
              {infoItem({
                label: "Arrival Date",
                children: FormatDate(data?.planned_arrival || "", "DD-MM-YYYY"),
                dataTestId: "truck-detail-arrival-date",
              })}
              {infoItem({
                label: "Delivery SLA",
                children: data?.sla,
                dataTestId: "truck-detail-sla",
              })}
              {infoItem({
                label: "DC",
                children: (
                  <a onClick={() => onClickDc(data?.dc_code || "")}>
                    <u>{data?.dc_code}</u>
                  </a>
                ),
                dataTestId: "truck-detail-dc",
              })}
              {infoItem({
                label: "Task Complete",
                children: data?.task_completed,
                dataTestId: "truck-detail-task-complete",
              })}
            </Flex>
          </div>
        </Card>
      )}
      {contextHolderNoti}
    </>
  );
};

export default TruckInformation;
