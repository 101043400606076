export const formatPhoneNumber = (rawValue: string) => {
  const trimmedValue = rawValue.trim();
  const cleanedValue = trimmedValue.replace(/[\s+\D-]/g, "");
  return cleanedValue;
};

export function toCapitalCase(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
