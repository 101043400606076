import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { provincesOptions } from "../../../shared/service/province-list-option";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import StoreMasterService from "../services/store-master-service";

interface Props {
  onSearch: (values: any) => void;
  onResetSearch: () => void;
  isLoading: boolean;
}

const FormSearch = ({ onSearch, onResetSearch, isLoading }: Props) => {
  const [form] = Form.useForm();
  const [signalResetForm, setSignalResetForm] = useState(false);

  useEffect(() => {
    StoreMasterService.signalResetForm.subscribe((loading) => {
      setSignalResetForm(loading);
    });
  }, []);

  useEffect(() => {
    if (signalResetForm) {
      form.resetFields();
    }
  }, [signalResetForm]);

  const companyOptions = [
    {
      label: "CJ",
      value: "CJ",
    },
    {
      label: "CJx",
      value: "CJx",
    },
    {
      label: "TD",
      value: "TD",
    },
  ];

  const registrationStatusOptions = [
    {
      label: "Register",
      value: "true",
    },
    {
      label: "Unregistered",
      value: "false",
    },
  ];

  const allowSendMessageOptions = [
    {
      label: "Enabled",
      value: "true",
    },
    {
      label: "Disabled",
      value: "false",
    },
  ];

  const enabledGeofenceOptions = [
    {
      label: "On",
      value: "true",
    },
    {
      label: "Off",
      value: "false",
    },
  ];

  const onFinish = (value: any) => {
    onSearch(value);
  };

  const onResetForm = () => {
    form.resetFields();
    onResetSearch();
  };

  return (
    <FormDs
      className="form-search"
      form={form}
      onFinish={onFinish}
      onReset={onResetForm}
      autoComplete="off"
      requiredMark={false}
    >
      <Row gutter={16}>
        <Col>
          <Form.Item label="Company" name="company">
            <SelectDs
              data-test-id="select-company"
              placeholder="Select Company"
              style={{ width: 200 }}
              options={companyOptions}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Store Number" name="store_number">
            <InputDs
              placeholder="Search Store Number"
              style={{ width: 200 }}
              onBlur={() => {
                if (form.getFieldValue("store_number")) {
                  form.setFieldValue("store_number", form.getFieldValue("store_number").trim());
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Store Name" name="store_name">
            <InputDs
              placeholder="Search Store Name"
              style={{ width: 200 }}
              onBlur={() => {
                if (form.getFieldValue("store_name")) {
                  form.setFieldValue("store_name", form.getFieldValue("store_name").trim());
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item label="Province" name="province">
            <SelectDs options={provincesOptions} style={{ width: 200 }} placeholder="Select Province" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Registration Status" name="registration_status">
            <SelectDs
              options={registrationStatusOptions}
              style={{ width: 200 }}
              placeholder="Registration Status"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Notification Permission" name="enabled_notification">
            <SelectDs
              options={allowSendMessageOptions}
              style={{ width: 200 }}
              placeholder="Notification Permission"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Geofencing Verification" name="enabled_checking_geofence">
            <SelectDs
              options={enabledGeofenceOptions}
              style={{ width: 200 }}
              placeholder="Geofencing Verification"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="reset">
            <ButtonDs type="default" htmlType="reset" disabled={isLoading}>
              Clear Filter
            </ButtonDs>
          </Form.Item>
          <Form.Item name="submit" style={{ paddingLeft: 13 }}>
            <ButtonDs type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
              Search
            </ButtonDs>
          </Form.Item>
        </Col>
      </Row>
    </FormDs>
  );
};

export default FormSearch;
