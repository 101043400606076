import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Eta, Mode, Sync } from "../models/delivery-order.model";
import dayjs from "dayjs";
import DeliveryOrderService from "../services/delivery-order-service";
import { PopoverAction, PopoverSyncAction } from "./PopoverAction";

interface Props {
  mode: Mode;
  dataSource: Eta[];
  syncDataSource: Sync[];
  isLoading: boolean;
}

const DeliveryOrderTable = ({ dataSource, isLoading, mode, syncDataSource }: Props) => {
  const columns: ColumnsType<Eta> = [
    {
      title: "DC",
      dataIndex: "dc_code",
      key: "dc_code",
      width: 50,
      align: "center",
      fixed: "left",
    },
    {
      title: "File name",
      dataIndex: "filename",
      key: "filename",
      width: 150,
    },
    {
      title: "Upload by",
      dataIndex: "uploaded_by",
      key: "uploaded_by",
      width: 100,
    },
    {
      title: "Upload time",
      dataIndex: "uploaded_time",
      key: "uploaded_time",
      width: 80,
      render: (_: string, record: Eta) => (
        <>
          <div>{record.uploaded_time && dayjs(record.uploaded_time).format("DD-MM-YYYY, HH:mm")}</div>
        </>
      ),
    },
    {
      title: "Record Uploaded",
      key: "record_uploaded",
      width: 60,
      align: "center",
      render: (_: string, record: Eta) => {
        return <>{record.record_uploaded > 0 ? record.record_uploaded : ""} </>;
      },
    },
    {
      title: "Action",
      width: 60,
      key: "action",
      align: "center",
      render: (_, record) => {
        return record.record_uploaded > 0 && <PopoverAction record={record} onAction={handleAction} />;
      },
    },
  ];

  const syncColumns: ColumnsType<Sync> = [
    {
      title: "DC",
      dataIndex: "dc_code",
      key: "dc_code",
      width: 50,
      align: "center",
      fixed: "left",
    },
    {
      title: "Last sync timestamp",
      dataIndex: "last_sync",
      key: "last_sync",
      width: 100,
      align: "center",
      render: (_: string, record: Sync) => {
        return (
          <>
            <div>{record.last_sync && dayjs(record.last_sync).format("DD-MM-YYYY, HH:mm")}</div>
          </>
        );
      },
    },
    {
      title: "Number of records",
      dataIndex: "total_task",
      key: "total_task",
      width: 100,
      align: "center",
      render: (_: string, record: Sync) => {
        return <>{record.total_task > 0 ? record.total_task : ""} </>;
      },
    },
    {
      title: "Action",
      width: 60,
      key: "action",
      align: "center",
      render: (_, record) => {
        return record.total_task > 0 && <PopoverSyncAction record={record} onAction={handleSyncAction} />;
      },
    },
  ];

  const handleAction = (result: { type: string; record: Eta }) => {
    if (result.type === "download_original_data") {
      DeliveryOrderService.downloadOriginalFile(result.record.dc_code).then(
        (response) => {
          const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];
          createFile(filename, response.data);
        },
        (error) => {
          console.log("error", error);
        },
      );
    }
  };

  const handleSyncAction = (result: { type: string; record: Sync }) => {
    if (result.type === "download_data") {
      DeliveryOrderService.downloadSyncLatestFile(result.record.dc_code).then(
        (response) => {
          const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];
          createFile(filename, response.data);
        },
        (error) => {
          console.log("error", error);
        },
      );
    }
  };

  const createFile = (fileName: string, data: Blob) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(data);
    link.setAttribute("download", decodeURI(fileName));
    link.click();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: "flex", alignItems: "baseline" }}>
          <h3>รายการข้อมูลการจัดส่ง</h3>
          <div className="data-date-text">
            ข้อมูลจัดส่งของวันที่ : {dayjs(new Date()).format("DD-MM-YYYY")}
          </div>
        </Col>
        <Col span={24}>
          {mode === Mode.SYNC ? (
            <Table
              className="table-custom-design-system"
              columns={syncColumns}
              dataSource={syncDataSource}
              loading={isLoading}
              pagination={false}
              rowKey={"dc_code"}
            />
          ) : (
            <Table
              className="table-custom-design-system"
              columns={columns}
              dataSource={dataSource}
              loading={isLoading}
              pagination={false}
              rowKey={"dc_code"}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DeliveryOrderTable;
