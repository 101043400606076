import { combineReducers, configureStore } from "@reduxjs/toolkit";

import forgotPassword from "../../feature/ForgotPassword/Slice";
import geofences from "../../feature/Geofences/Slice";
import gpsTracking from "../../feature/GpsTracking/Slice";
import login from "../../feature/Login/LoginSlice";
import toggleSider from "../../shared/component/HeaderComponent/HeaderComponentSlice";
import truckMonitor from "../../feature/TruckMonitor/Slice";
import updateTrackingStatus from "../../feature/GpsTracking/SliceUpdateTrackingStatus";
import featuresFlags from "../../feature/DeliveryOrder/featuresFlagsSlice";
import { useDispatch } from "react-redux";

const reducers = {
  login,
  toggleSider,
  truckMonitor,
  gpsTracking,
  geofences,
  updateTrackingStatus,
  forgotPassword,
  featuresFlags,
};

const combinedReducer = combineReducers(reducers);

const reducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
