import { CheckCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import {
  Card,
  Col,
  Layout,
  Row,
  Skeleton,
  Space,
  Switch,
  Upload,
  UploadFile,
  UploadProps,
  message,
  notification,
} from "antd";
import DraggerFileComponent from "../../shared/component/DraggerFileComponent";
import { Form, FormDs, SelectDs } from "design-system";
import { dcList, dcLists } from "../../utils/DClists";
import React, { useEffect, useRef, useState } from "react";
import DeliveryOrderTable from "./components/DeliveryOrderTable";
import DeliveryOrderService from "./services/delivery-order-service";
import "./style.scss";
import { Cell, ErrorHeader, Eta, Mode, Sync } from "./models/delivery-order.model";
import TooltipComponent from "../../shared/component/TooltipComponent";
import LocalstorageService from "../../shared/service/Localstorage.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import ApiClient from "../../shared/api/ApiClient";
import ConfirmModal from "../../shared/component/ConfirmModal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../shared/store/store";
import { enabledToggleTMSSync, getFeaturesFlags, setFeaturesFlagState } from "./featuresFlagsSlice";

const { Content } = Layout;

const DeliveryOrder = () => {
  const role = LocalstorageService.getCurrentRole();
  const [form] = Form.useForm();
  const [fileUploaded, setFileUploaded] = useState<UploadFile[]>([]);
  const [etaList, setEtaList] = useState<Eta[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [errorMessageFileUpload, setErrorMessageFileUpload] = useState<{
    title: string;
    description: string;
  } | null>(null);
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const dcCodeList = useRef<string[]>(dcLists().map((dc) => dc.value));
  const renderCount = useRef(0);

  const [syncList, setSyncList] = useState<Sync[]>([]);
  const toggleValue = useRef<boolean>(false);
  const [isToggleSync, setIsToggleSync] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoadingToggleSync, setIsLoadingToggleSync] = useState(false);
  const enabledToggleTMSSyncState = useSelector(enabledToggleTMSSync);
  const dispatch = useAppDispatch();
  type ErrorMessage = {
    [key: string]: string;
  };
  const errorMessageV2: ErrorMessage = {
    invalid_planned_date: "Planned date ไม่ถูกต้อง",
    invalid_ldt_number: "LDT number ไม่ถูกต้อง",
    invalid_store_number: "Store number ไม่ถูกต้อง",
    invalid_stop: "Stop ไม่ถูกต้อง",
    invalid_transportation_name: "Transportation name ไม่ถูกต้อง",
    invalid_truck_type: "Truck type ไม่ถูกต้อง",
    invalid_truck_plate_number: "Truck plate ไม่ถูกต้อง",
    invalid_driver_name: "Driver name ไม่ถูกต้อง",
    invalid_driver_mobile_number: "Driver mobile number ไม่ถูกต้อง",
    invalid_eta_start: "ETA start ไม่ถูกต้อง",
    invalid_eta_end: "ETA end ไม่ถูกต้อง",
    invalid_planned_load_date: "Planned load date ไม่ถูกต้อง",
    invalid_planned_load_time: "Planned load time ไม่ถูกต้อง",
    invalid_planned_arrival: "Planned arrival ไม่ถูกต้อง",
  };

  useQuery({
    queryKey: ["featuresFlags"],
    queryFn: () => getFeaturesFlags().then((data) => dispatch(setFeaturesFlagState(data))),
    retry: 1,
    refetchOnWindowFocus: false,
  });
  const [isLodingSyncData, setIsLodingSyncData] = useState<boolean>(false);

  useEffect(() => {
    if (dcCodeList.current.length > 0) {
      getDataEta();
    } else {
      dcList.subscribe((dcs) => {
        if (dcs && renderCount.current === 0) {
          renderCount.current = 1;
          dcCodeList.current = dcs.map((dc) => dc.value);
          getDataEta();
        }
      });
    }

    return () => {
      renderCount.current = 0;
      notification.destroy();
    };
  }, []);

  const getDataEta = () => {
    setLoading(true);
    DeliveryOrderService.getEtas().then(
      (res) => {
        const etasListBindingData = mapEtasList(dcCodeList.current, res);
        setEtaList(etasListBindingData);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      },
    );
  };

  const mapEtasList = (dcCodeList: string[], etasList: Record<string, Partial<Eta>>): Eta[] =>
    dcCodeList.map((dcCode) => ({
      ...createEmptyEtaFile(dcCode),
      ...etasList[dcCode],
    }));

  const mapSyncList = (dcCodeList: string[], syncList: Record<string, Partial<Sync>>): Sync[] =>
    dcCodeList.map((dcCode) => ({
      ...createEmptySync(dcCode),
      ...syncList[dcCode],
    }));

  const createEmptyEtaFile = (dcCode: string): Eta => ({
    dc_code: dcCode,
    uploaded_date: "",
    filename: "",
    uploaded_by: "",
    uploaded_time: "",
    total: 0,
    completed_count: 0,
    file_path: "",
    record_uploaded: 0,
  });

  const createEmptySync = (dcCode: string): Sync => ({
    dc_code: dcCode,
    last_sync: "",
    total_task: 0,
    sent_by: "",
    sent_time: "",
  });

  const props: UploadProps = {
    name: "file",
    multiple: false,
    customRequest: ({ onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 500);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setFileUploaded(info.fileList);
        setErrorMessageFileUpload(null);
      }
    },
    beforeUpload: (file: any) => {
      const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        setErrorMessageFileUpload({
          title: "Format ของ File ไม่ถูกต้อง",
          description:
            "ไม่สามารถ Upload ได้เนื่องจาก Format ไม่ถูกต้อง กรุณา Upload เฉพาะไฟล์ .xlsx เท่านั้น",
        });
      }
      return isXlsx || Upload.LIST_IGNORE;
    },
    onRemove(file: UploadFile) {
      const index = fileUploaded.indexOf(file);
      if (index > -1) {
        const recentList = fileUploaded.filter((_, fidx) => fidx !== index);
        setFileUploaded(recentList);
      }
    },
  };

  const onSubmit = () => {
    form.validateFields().then(() => {
      const dc_name = form.getFieldValue("dc_name");
      const fileName = fileUploaded[0].originFileObj?.name || "";

      if (fileName.toLocaleLowerCase().indexOf(dc_name.toLocaleLowerCase()) === -1) {
        setErrorMessageFileUpload({
          title: `ชื่อ File ไม่สอดคล้องกับศูนย์กระจายสินค้าที่เลือก `,
          description: `ชื่อ File ต้องมีชื่อศูนย์กระจายสินค้า ${dc_name} เป็นส่วนหนึ่งของชื่อ File`,
        });
        return false;
      }

      notification.destroy();
      setLoading(true);
      DeliveryOrderService.uploadEta(dc_name, fileUploaded[0].originFileObj as File).then(
        () => {
          setFileUploaded([]);
          setLoading(false);
          openNotiSuccess("อัพโหลดไฟล์สำเร็จ", fileName, dc_name);
          form.resetFields();
          getDataEta();
        },
        (err) => {
          const cellErrorResponse = err.response.data.cells;
          const errorHeaderResponse = err.response.data;

          if (cellErrorResponse) {
            openNotificationErrorCell("อัพโหลดไฟล์ไม่สำเร็จ", cellErrorResponse);
          } else {
            openNotificationErrorHeader("อัพโหลดไฟล์ไม่สำเร็จ", errorHeaderResponse);
          }
          form.resetFields();
          setFileUploaded([]);
          setLoading(false);
        },
      );
    });
  };

  const renderInvalidHeader = (data: ErrorHeader) => {
    type ErrorMessage = {
      [key: string]: string;
    };
    const errorMessge: ErrorMessage = {
      invalid_file_header_column_length: "จำนวน Column ไม่ถูกต้อง",
      invalid_file_header_column_name: "ชื่อ Column ไม่ถูกต้อง",
    };
    return (
      <div>
        <div>{errorMessge[data.code]}</div>
        <li>{data.message}</li>
      </div>
    );
  };

  const renderInvalidCell = (cell: Cell) => {
    const errorMessage = errorMessageV2;
    if (cell.code === "inconsistent_stop" || cell.code === "inconsistent_store_number") {
      return (
        <li>
          หมายเลขสโตร์ {cell.data} {errorMessage[cell.code]}
        </li>
      );
    } else if (cell.code === "inconsistent_truckplate") {
      return (
        <li>
          ป้ายทะเบียน {cell.data} {errorMessage[cell.code]}
        </li>
      );
    } else {
      return (
        <li>
          {errorMessage[cell.code]} แถวที่ {cell.row} คอลัมน์ {cell.col}
        </li>
      );
    }
  };

  const openNotificationErrorCell = (message: string, cells: any) => {
    notification.destroy();
    const cellError = cells?.map((cell: Cell, index: number) => (
      <React.Fragment key={index}>{renderInvalidCell(cell)}</React.Fragment>
    ));
    notification.open({
      type: "error",
      className: "upload-eta-notification noti-error",
      message: message,
      description: cellError,
      placement: "top",
      duration: 0,
    });
  };

  const openNotificationErrorHeader = (message: string, data: ErrorHeader) => {
    notification.destroy();
    const cellError = renderInvalidHeader(data);
    notification.open({
      type: "error",
      className: "upload-eta-notification noti-error",
      message: message,
      description: cellError,
      placement: "top",
      duration: 0,
    });
  };

  const openNotiSuccess = (message: string, fileName: string, dcName: string) => {
    notificationComponent.success({
      message: <h4 style={{ fontSize: "16px" }}>{message}</h4>,
      description: `Upload file ${fileName} ศูนย์กระจายสินค้า ${dcName} สำเร็จ`,
      className: "success-notification",
      icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
      placement: "topRight",
    });
  };

  const toggleSync = useMutation({
    mutationFn: (enabled: boolean) => {
      setIsLoadingToggleSync(true);
      return ApiClient.put("/v2/tms-sync/toggle", { enabled: enabled });
    },
  });

  const getSyncStatus = async () => {
    const response = await ApiClient.get("/v2/tms-sync");
    return response.data;
  };

  const getSyncInfo = async () => {
    setIsLodingSyncData(true);
    const response = await ApiClient.get("/v2/tms-sync/info");
    return response.data;
  };

  const fetchSyncStatus = useQuery({
    queryKey: ["getSyncStatus"],
    queryFn: () => getSyncStatus(),
    retry: 1,
  });

  const fetchSyncInfo = useQuery({
    enabled: isToggleSync,
    queryKey: ["getSyncInfo"],
    queryFn: () =>
      getSyncInfo().then((res) => {
        const syncMap = res.reduce((map: any, sync: Sync) => {
          map[sync.dc_code] = sync;
          return map;
        }, {} as Record<string, Sync>);
        const syncListBindingData = mapSyncList(dcCodeList.current, syncMap);
        setSyncList(syncListBindingData);

        return syncListBindingData;
      }),
    retry: 1,
  });

  useEffect(() => {
    if (fetchSyncStatus.isSuccess) {
      setIsToggleSync(fetchSyncStatus.data.enabled);
    }
  }, [fetchSyncStatus]);

  useEffect(() => {
    if (fetchSyncInfo.isSuccess) {
      setIsLodingSyncData(false);
    }
  }, [fetchSyncInfo]);

  const onChangeToggle = (checked: boolean) => {
    setIsConfirmModalOpen(true);
    toggleValue.current = checked;
  };

  const onChangeConfirm = () => {
    const mode = toggleValue.current ? "เปิด" : "ปิด";
    toggleSync.mutate(toggleValue.current, {
      onSuccess: () => {
        fetchSyncStatus.refetch();
        messageApi.success(`${mode}การใช้งาน Data Sync สำเร็จ`);
      },
      onError: () => {
        toggleValue.current = !toggleValue.current;
        messageApi.error(`${mode}การใช้งาน Data Sync ไม่สำเร็จ`);
      },
      onSettled: () => {
        setIsLoadingToggleSync(false);
        setIsConfirmModalOpen(false);
      },
    });
  };

  const ManualUploadView = () => {
    return (
      <>
        {role && !role.includes("planner") && (
          <Col span={24}>
            <Card>
              <FormDs form={form}>
                <h3>Upload File</h3>
                <Form.Item
                  name="dc_name"
                  label="ศูนย์กระจายสินค้า"
                  rules={[{ required: true, message: "กรุณาเลือกศูนย์กระจายสินค้า" }]}
                >
                  <SelectDs
                    placeholder="กรุณาเลือกศูนย์กระจายสินค้า"
                    options={dcCodeList.current.map((dcCode) => ({
                      label: dcCode,
                      value: dcCode,
                    }))}
                    style={{ width: "258px" }}
                    data-test-id="select_dc_name"
                    onChange={() => {
                      setErrorMessageFileUpload(null);
                    }}
                  />
                </Form.Item>
                <DraggerFileComponent
                  draggerProps={props}
                  contentText="Click or drag file to this area for upload"
                  descriptionText="File support .xlsx only"
                  fileUpload={fileUploaded}
                  fileTemplateHref={"/files_download/ETA_Template_File_v8.0.xlsx"}
                  onSubmit={onSubmit}
                  errorMessage={errorMessageFileUpload}
                  isLoading={loading}
                  data-test-id="select_file_eta"
                />
              </FormDs>
            </Card>
          </Col>
        )}
        <Col span={24}>
          <Card>
            <DeliveryOrderTable
              mode={Mode.UPLOAD}
              dataSource={etaList}
              isLoading={loading}
              syncDataSource={[]}
            />
          </Card>
        </Col>
      </>
    );
  };

  const SyncView = () => {
    return (
      <Col span={24}>
        <Card>
          <DeliveryOrderTable
            mode={Mode.SYNC}
            dataSource={[]}
            isLoading={isLodingSyncData}
            syncDataSource={syncList}
          />
        </Card>
      </Col>
    );
  };

  const enabledToggleFeatView = () => {
    return (
      <Content className="delivery-order-content">
        {fetchSyncStatus.isFetching || isLoadingToggleSync ? (
          <Skeleton active />
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
              <h1>Delivery Order</h1>
              <TooltipComponent title="ส่วนการอัปโหลดรายการจัดส่งสินค้า" />
              {role && role.includes("admin") && (
                <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                  <p style={{ marginRight: 8, marginBottom: 0, fontWeight: "bold" }}>Data Sync: </p>
                  <p style={{ marginRight: 8, marginBottom: 0 }}>
                    {isToggleSync ? "เปิดใช้งาน" : "ปิดใช้งาน"}
                  </p>
                  <Switch checked={isToggleSync} onChange={onChangeToggle} style={{ marginLeft: 8 }} />
                </div>
              )}
            </div>
            <Row gutter={[16, 16]}>{isToggleSync ? <SyncView /> : <ManualUploadView />}</Row>
          </>
        )}
      </Content>
    );
  };

  const disabledToggleFeatView = () => {
    return (
      <Content className="delivery-order-content">
        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h1>Delivery Order</h1>
          <TooltipComponent title="ส่วนการอัปโหลดรายการจัดส่งสินค้า" />
        </div>
        <Row gutter={[16, 16]}>
          <ManualUploadView />
        </Row>
      </Content>
    );
  };

  const toggleText = toggleValue.current ? "เปิดการใช้งาน" : "ปิดการใช้งาน";

  return (
    <DesignSystemTheme>
      {enabledToggleTMSSyncState ? enabledToggleFeatView() : disabledToggleFeatView()}
      <ConfirmModal
        open={isConfirmModalOpen}
        closeIcon={true}
        title={
          <Space>
            <QuestionCircleOutlined />
            <span>{toggleText} Data Sync</span>
          </Space>
        }
        onClickConfirmCancel={() => {
          setIsConfirmModalOpen(false);
        }}
        onClickConfirmOK={onChangeConfirm}
        loading={isLoadingToggleSync}
      >
        <p>ต้องการ{toggleText} Data Sync หรือไม่</p>
        {toggleValue.current ? (
          ""
        ) : (
          <p style={{ fontWeight: "bold" }}>
            หากต้องการ Manual upload ในภายหลัง โปรดเพิ่มข้อมูลที่มีการซิงค์ในไฟล์อีกครั้งก่อน Upload
          </p>
        )}
      </ConfirmModal>
      {contextHolderNoti}
      {contextHolderMessage}
    </DesignSystemTheme>
  );
};

export default DeliveryOrder;
