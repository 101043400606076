import { useEffect } from "react";
import { Button, Card, Layout, Space } from "antd";
import "./style.scss";
import tdLogo from "../../assets/images/logo/logo-td.svg";
import cjLogo from "../../assets/images/logo/logo-cj.svg";
import tdmLogo from "../../assets/images/logo/logo-tdm.svg";
import LocalstorageService from "../../shared/service/Localstorage.service";
import { Company } from "../../shared/type/Company";

const { Content } = Layout;

const SelectCompany = () => {
  const currentUser = LocalstorageService.getUserAuthInfo();

  useEffect(() => {
    const accessorCompanies = currentUser?.accessor_companies || [];
    if (currentUser) {
      if (accessorCompanies.length === 1) {
        window.location.href = "/live-monitoring";
      }
    }
  }, [currentUser]);

  const onSelectCompany = (company: Company) => {
    LocalstorageService.setCompany(company);
    if (company === "TDM") {
      window.location.href = "/booking-request";
    } else {
      window.location.href = "/live-monitoring";
    }
  };

  return (
    <Content className="select-company-content">
      <Card className="select-company-card">
        <div className="title">Login as</div>
        <div className="description">กรุณาเลือกบริษัทเพื่อเข้าสู่ระบบ</div>
        {currentUser?.accessor_companies?.includes("TD") ? (
          <Button
            onClick={() => {
              onSelectCompany("TD");
            }}
          >
            <Space>
              <img src={tdLogo} alt="td-logo" />
              <div>TD Tawandang</div>
            </Space>
          </Button>
        ) : null}

        {currentUser?.accessor_companies?.includes("CJ") ? (
          <Button
            onClick={() => {
              onSelectCompany("CJ");
            }}
          >
            <Space>
              <img src={cjLogo} alt="cj-logo" />
              <div>CJ Express</div>
            </Space>
          </Button>
        ) : null}

        {currentUser?.accessor_companies?.includes("TDM") ? (
          <Button
            onClick={() => {
              onSelectCompany("TDM");
            }}
          >
            <Space>
              <img src={tdmLogo} alt="tdm-logo" />
              <div>TDM Logistics</div>
            </Space>
          </Button>
        ) : null}
      </Card>
    </Content>
  );
};

export default SelectCompany;
