import "./style.scss";
import {
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  ContainerOutlined,
  MenuOutlined,
  ClockCircleOutlined,
  LogoutOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Divider, Dropdown, Flex, Layout, Menu, Space } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cjLogo from "../../../assets/images/icon/icon-cj.svg";
import tdLogo from "../../../assets/images/icon/icon-td.svg";
import tdmLogo from "../../../assets/images/icon/tdm_icon.svg";
import baomoveLogo from "../../../assets/images/logo/baomove_full.png";
import baomoveFavicon from "../../../assets/images/logo/baomove_favicon.png";
import LocalstorageService from "../../service/Localstorage.service";
import SessionstorageService from "../../service/Sessionstorage.service";
import { Company } from "../../type/Company";
import { SelectDs } from "design-system";
import { toCapitalCase } from "../../../utils/normalizers";
import { getAuth } from "../../api/FirebaseAuth";

const { Sider } = Layout;

interface MenuItem {
  key: string;
  label: string;
  icon?: JSX.Element;
  onClick?: () => void;
  allow: string[];
  allowCompany: string[];
  children?: MenuItem[];
}

const logoMapping = new Map<string, { logo: string; name: string }>([
  ["CJ", { logo: cjLogo, name: "CJ Express" }],
  ["TD", { logo: tdLogo, name: "TD Tawandang" }],
  ["TDM", { logo: tdmLogo, name: "TDM Logistics" }],
]);

const allCompany = ["CJ", "TD", "TDM"];
const cjandtdCompany = ["CJ", "TD"];
const managerTier = ["root", "admin", "manager"];
const plannerTier = ["root", "admin", "manager", "operation", "planner"];
const callCenterTier = ["root", "admin", "manager", "operation", "planner", "call_center"];

const pathToKeyMap = new Map<string, string>([
  ["/delivery-order", "1"],
  ["/live-monitoring", "2-1"],
  ["/return-eta", "2-2"],
  ["/return-eta-request", "2-3"],
  ["/user-management", "3"],
  ["/store-master", "4"],
  ["/messaging-status", "5"],
  ["/task-status-report", "6"],
  ["/pod-report", "7"],
  ["/booking-request", "8"],
  ["/drop-monitoring", "9-1"],
]);

export default function SiderComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState("0");
  const [isCollapse, setIsCollapse] = useState(LocalstorageService.getConfigToggleSider());
  const selectedCompany = LocalstorageService.getCompany();
  const userAuthInfoData = LocalstorageService.getUserAuthInfo();
  const currentRole = LocalstorageService.getCurrentRole();
  const appVersion = process.env.REACT_APP_VERSION;

  useEffect(() => {
    const key = pathToKeyMap.get(location.pathname || "") || "0";
    setCurrent(key);
  }, [current, location.pathname]);

  const menuItems: MenuItem[] = [
    {
      key: "1",
      icon: <ClockCircleOutlined />,
      label: "Delivery Order",
      allow: plannerTier,
      allowCompany: cjandtdCompany,
      onClick: () => navigate("/delivery-order"),
    },
    {
      key: "2",
      icon: <CarOutlined />,
      label: "Truck Monitor",
      allow: callCenterTier,
      allowCompany: cjandtdCompany,
      children: [
        {
          key: "2-1",
          label: "Live Monitoring",
          allow: callCenterTier,
          allowCompany: cjandtdCompany,
          onClick: () => navigate("/live-monitoring"),
        },
        {
          key: "2-3",
          label: "Return ETA",
          allow: plannerTier,
          allowCompany: cjandtdCompany,
          onClick: () => navigate("/return-eta-request"),
        },
      ],
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "User Management",
      allow: managerTier,
      allowCompany: allCompany,
      onClick: () => {
        sessionStorage.removeItem("bookingRequestState");
        navigate("/user-management");
      },
    },
    {
      key: "4",
      icon: <ShopOutlined />,
      label: "Store Master",
      allow: callCenterTier,
      allowCompany: cjandtdCompany,
      onClick: () => navigate("/store-master"),
    },
    {
      key: "5",
      icon: <MailOutlined />,
      label: "Messaging Status",
      allow: plannerTier,
      allowCompany: cjandtdCompany,
      onClick: () => navigate("/messaging-status"),
    },
    {
      key: "6",
      icon: <FileTextOutlined />,
      label: "Report",
      allow: plannerTier,
      allowCompany: cjandtdCompany,
      onClick: () => navigate("/task-status-report"),
    },
    {
      key: "7",
      icon: <CheckCircleOutlined />,
      label: "PoD Report",
      allow: plannerTier,
      allowCompany: cjandtdCompany,
      onClick: () => {
        sessionStorage.removeItem("podReportState");
        navigate("/pod-report");
      },
    },
    {
      key: "8",
      icon: <ContainerOutlined />,
      label: "Booking Requests",
      allow: ["admin"],
      allowCompany: ["TDM"],
      onClick: () => {
        sessionStorage.removeItem("podReportState");
        navigate("/booking-request");
      },
    },
    {
      key: "9",
      icon: <CarOutlined />,
      label: "Live Monitoring",
      allow: ["admin"],
      allowCompany: ["TDM"],
      children: [
        {
          key: "9-1",
          label: "Drop Monitoring",
          allow: ["admin"],
          allowCompany: ["TDM"],
          onClick: () => {
            sessionStorage.removeItem("bookingRequestState");
            navigate("/drop-monitoring");
          },
        },
      ],
    },
  ];

  const onClickCompany = (company: Company) => {
    LocalstorageService.setCompany(company);
    SessionstorageService.removeDCMaster();
    window.location.href = company === "TDM" ? "/booking-request" : "/live-monitoring";
  };

  const CompanySection = () => {
    const companyOptions = userAuthInfoData?.accessor_companies.map((company) => ({
      key: company,
      label: (
        <Space style={{ display: "flex", width: "100%", fontSize: "14px" }}>
          <img src={logoMapping.get(company)?.logo ?? ""} alt="logo-company" width={38} height={24} />
          {!isCollapse && logoMapping.get(company)?.name}
        </Space>
      ),
      value: company,
    }));

    const displayCompany = () =>
      selectedCompany && (
        <Space className={`company-section ${isCollapse && "collapsed"}`}>
          <img src={logoMapping.get(selectedCompany)?.logo} alt="logo-company" />
          {!isCollapse && logoMapping.get(selectedCompany)?.name}
        </Space>
      );

    const oneCompany = () => (
      <Flex vertical gap={8}>
        {!isCollapse && <span>Company</span>}
        {displayCompany()}
      </Flex>
    );

    const multipleCompany = () => {
      const collapsedCompanyOptions = userAuthInfoData?.accessor_companies
        .filter((company) => company !== selectedCompany)
        .map((company) => ({
          key: company,
          label: (
            <Space style={{ display: "flex", width: "100%", fontSize: "14px" }}>
              <img src={logoMapping.get(company)?.logo ?? ""} alt="logo-company" width={38} height={24} />
            </Space>
          ),
          onClick: () => onClickCompany(company as Company),
        }));

      return isCollapse ? (
        <Dropdown
          trigger={["click"]}
          className="collapsed-company-selection"
          placement="bottom"
          menu={{ items: collapsedCompanyOptions }}
        >
          <a onClick={(e) => e.preventDefault()}>{displayCompany()}</a>
        </Dropdown>
      ) : (
        <Flex vertical gap={8}>
          <span>Company</span>
          <SelectDs
            className="company-selection"
            data-testid="company-selection"
            options={companyOptions}
            defaultValue={selectedCompany}
            onChange={(e) => onClickCompany(e)}
          />
        </Flex>
      );
    };

    return (
      userAuthInfoData?.accessor_companies && (
        <div className="select-company-section">
          {userAuthInfoData.accessor_companies.length === 1 ? oneCompany() : multipleCompany()}
        </div>
      )
    );
  };

  const LogoSection = () => {
    const onClickToggle = () => {
      setIsCollapse(!isCollapse);
      LocalstorageService.setConfigToggleSider({ isSiderCollapse: !isCollapse });
    };

    return (
      <Flex className="logo-section" justify="space-between" vertical={isCollapse} align="center" gap={16}>
        <img
          src={isCollapse ? baomoveFavicon : baomoveLogo}
          alt="baomove-logo"
          height={isCollapse ? 24 : undefined}
          width={isCollapse ? undefined : 100}
        />
        <div className="toggle-icon" onClick={onClickToggle}>
          <MenuOutlined size={24} />
        </div>
      </Flex>
    );
  };

  /// Footer Section
  const UserSection = () => {
    const firstName = userAuthInfoData?.first_name ?? "";
    const lastName = userAuthInfoData?.last_name ?? "";
    const name = firstName && lastName ? `${firstName} ${lastName}` : "-";
    const role = currentRole ? toCapitalCase(currentRole) : "-";

    const UserImage = () => <Avatar icon={<UserOutlined />} className="avatar" />;

    return isCollapse ? (
      <Flex className="user-section" align="center" justify="center">
        <UserImage />
      </Flex>
    ) : (
      <Flex className="user-section" gap={8} align="center">
        <UserImage />
        <Col className="user-info">
          <span className="title">{name}</span>
          <span className="sub-title">{role}</span>
        </Col>
      </Flex>
    );
  };

  const LogoutSection = () => {
    const onClickLogout = () => {
      getAuth()
        .signOut()
        .then(() => {
          LocalstorageService.clear();
          SessionstorageService.removeDCMaster();
          navigate("/login");
        });
    };

    const LogoutButton = ({ isCollapse }: { isCollapse: boolean }) => (
      <div onClick={onClickLogout} style={{ cursor: "pointer" }}>
        <Space align="center">
          <LogoutOutlined />
          {!isCollapse && <span>Log Out</span>}
        </Space>
      </div>
    );

    return isCollapse ? (
      <Flex className="logout" justify="center" align="center">
        <LogoutButton isCollapse={isCollapse} />
      </Flex>
    ) : (
      <Flex className="logout" justify="space-between" align="baseline" wrap={false}>
        <LogoutButton isCollapse={isCollapse} />
        <span className="version">v.{appVersion}</span>
      </Flex>
    );
  };

  const FooterSection = () => (
    <Space direction="vertical" size={8}>
      <Divider style={{ margin: "0" }} />
      <UserSection />
      <Divider style={{ margin: "0" }} />
      <LogoutSection />
    </Space>
  );
  /// Footer Section

  const filterMenuItem = (menuItems: MenuItem[]) =>
    menuItems.filter(
      (x) =>
        x.allow.includes(userAuthInfoData?.role[selectedCompany || ""] ?? "") &&
        x.allowCompany.includes(selectedCompany || ""),
    );

  const menuItemPermission = filterMenuItem(menuItems).map((x) => ({
    key: x.key,
    label: x.label,
    children: x.children
      ? filterMenuItem(x.children).map((c) => ({
          key: c.key,
          label: c.label,
          onClick: c.onClick,
        }))
      : undefined,
    icon: x.icon,
    onClick: x.onClick,
  }));

  const getInitialOpenKeys = (path: string) => {
    // Return empty array when collapsed to prevent auto-open menu item
    if (isCollapse) return [];
    const defaultOpenKey = pathToKeyMap.get(path)?.charAt(0);
    return defaultOpenKey ? [defaultOpenKey] : [];
  };

  return (
    <Sider className="sider-custom" trigger={null} collapsible collapsed={isCollapse}>
      <Flex vertical justify="space-between" style={{ height: "100%" }}>
        <Flex vertical>
          <LogoSection />
          <Divider style={{ margin: "0" }} />
          <CompanySection />
          <Menu
            onClick={(e) => setCurrent(e.key)}
            mode="inline"
            defaultOpenKeys={getInitialOpenKeys(location.pathname)}
            selectedKeys={[current]}
            items={menuItemPermission}
            style={{ borderRight: 0 }}
          />
        </Flex>
        <FooterSection />
      </Flex>
    </Sider>
  );
}
