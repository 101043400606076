import { Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import SiderComponent from "../shared/component/SiderComponent/SiderComponent";
import { Content } from "antd/es/layout/layout";

const GpsTrackingLayout = () => {
  const location = useLocation();

  return (
    <Layout className="management-layout">
      {/* <HeaderComponent
        onToggle={(toggleState) => {
          setCollapsed(toggleState);
        }}
      /> */}
      <Layout hasSider>
        {location.pathname !== "/select-company" &&
          location.pathname !== "/agent-access-token" &&
          location.pathname !== "/external-api-key" && <SiderComponent />}

        <Layout style={{ height: "100vh" }}>
          <Content style={{ overflow: "auto" }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default GpsTrackingLayout;
