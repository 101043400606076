import { Flex } from "antd";
import TruckInformation from "./TruckInformation";
import {
  dehydrate,
  DehydratedState,
  hydrate,
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import liveMonitoringService from "../services/live-monitoring-service";
import AlertMessage from "../../../shared/component/AlertMessage";
import notfoundImage from "../../../assets/images/exception/not-found.png";
import TaskList from "./TaskList";
import TruckDetailMap, { TruckDetailMapRef } from "./TruckDetailMap";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

interface TruckDetailProps {
  tripId: string;
}

const TruckDetailDrawerContent = ({ tripId }: TruckDetailProps) => {
  const mapRef = useRef<TruckDetailMapRef>(null);
  const [isCalEta, setIsCalEta] = useState(false);
  const persistantQueryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        networkMode: "always",
      },
      mutations: {
        retry: 1,
        networkMode: "always",
      },
    },
  });

  const loadPersistState = (key: string, queryClient: QueryClient) => {
    const storeState = localStorage.getItem(key);
    if (storeState) {
      let state = JSON.parse(storeState) as DehydratedState;
      state = clearPersistExpireState(state, 1000 * 60 * 30);
      hydrate(queryClient, state);
    }
  };

  const clearPersistExpireState = (state: DehydratedState, expireDuration: number): DehydratedState => {
    return {
      ...state,
      queries: state.queries.filter((query) => {
        return dayjs().diff(dayjs(query.state.dataUpdatedAt), "millisecond") < expireDuration;
      }),
    };
  };

  const savePersistState = (key: string, queryClient: QueryClient) => {
    const dehydrateState = dehydrate(queryClient);
    localStorage.setItem(key, JSON.stringify(dehydrateState));
  };

  useEffect(() => {
    loadPersistState("cal-eta-cache", persistantQueryClient);
    const unsubscribe = persistantQueryClient.getQueryCache().subscribe(() => {
      savePersistState("cal-eta-cache", persistantQueryClient);
    });
    return unsubscribe;
  }, []);

  const fetchInterval = 2 * 1000 * 60;
  const {
    data = { trip_info: null, task_list: [] },
    isLoading: tripLoading,
    isError,
  } = useQuery({
    queryKey: ["trip", tripId],
    queryFn: () => liveMonitoringService.getTripDetail(tripId),
    retry: 1,
    refetchOnWindowFocus: false,
    refetchInterval: fetchInterval,
    enabled: () => (tripId ? true : false),
  });

  const {
    data: taskETA = [],
    refetch,
    isFetching: etaLoading,
    error: etaError,
    isError: isEtaError,
  } = useQuery(
    {
      queryKey: ["local-storage", "eta", "calculate", tripId],
      queryFn: async () => liveMonitoringService.getTripETA(tripId),
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: () => Boolean(tripId) && isCalEta,
    },
    persistantQueryClient,
  );

  if (isEtaError) {
    console.log("ETA Error: ", etaError);
  }

  const moveToMarker = (id: string) => {
    if (mapRef.current) {
      mapRef.current.moveToMarker(id);
    }
  };

  const queryClient = useQueryClient();

  const updateTripDetailMutation = useMutation({
    mutationFn: (id: string) => liveMonitoringService.getTripDetail(id || ""),
    onSuccess: (response, variables) => {
      queryClient.setQueryData(["trip", variables], response);
    },
  });

  return (
    <div>
      {isError ? (
        <>
          <AlertMessage
            type="error"
            message="Data Unavailable"
            description="The information you are trying to access has been deleted or is currently inaccessible."
          />
          <Flex align="center" justify="center" vertical style={{ height: "calc(100% - 200px)" }}>
            <img src={notfoundImage} width={140} height={194} alt="not-found" />
          </Flex>
        </>
      ) : (
        <Flex style={{ minHeight: "100%" }}>
          <div className="left-panel">
            <TaskList
              loading={tripLoading}
              taskETA={taskETA}
              data={data.task_list}
              dataTripInfo={data.trip_info || null}
              onClickStore={moveToMarker}
              fetchTripDetail={updateTripDetailMutation}
              tripId={tripId}
            />
          </div>

          <div className="right-panel">
            <Flex vertical flex={1} gap={24}>
              <TruckInformation
                loading={tripLoading}
                isFetchingETA={etaLoading}
                data={data.trip_info}
                onClickDc={moveToMarker}
                onClickTruckPlate={moveToMarker}
                onClickUpdateETA={async () => {
                  setIsCalEta(true);
                  await refetch();
                }}
              />
              <TruckDetailMap
                trackingId={data.trip_info?.trip_uuid || ""}
                tripDetail={data || { trip_info: null, task_list: [] }}
                isLoadingDriverDetail={tripLoading}
                ref={mapRef}
              />
            </Flex>
          </div>
        </Flex>
      )}
    </div>
  );
};

export default TruckDetailDrawerContent;
