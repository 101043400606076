import "./style.scss";
import { Button } from "antd";
import { useEffect, useState } from "react";
import emptyMarker from "../../../assets/images/map/empty_marker.png";
import { checkTomTomApiKey } from "./Slice";
import { RedoOutlined } from "@ant-design/icons";

interface TomTomMapProps {
  apiKey: string;
  mapContainer: React.RefObject<HTMLDivElement>;
  children?: JSX.Element;
}
const TomtomMapComponent = ({ apiKey, mapContainer, children }: TomTomMapProps) => {
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    validateTomTomApiKey();
  }, []);
  const validateTomTomApiKey = () => {
    checkTomTomApiKey(apiKey as string)
      .then(() => {
        setIsError(false);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  return (
    <>
      {isError ? (
        <div className="cannot-connect-tomtommap">
          <img src={emptyMarker} alt="empty-marker" />
          <p className="error-occurred">เกิดข้อผิดพลาด</p>
          <p className="please-try-again">ไม่สามารถเชื่อมต่อกับระบบได้ กรุณาลองใหม่อีกครั้ง</p>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            icon={<RedoOutlined />}
          >
            โหลดอีกครั้ง
          </Button>
        </div>
      ) : (
        <>
          <div style={{ height: "100%", position: "relative", borderRadius: 8 }} ref={mapContainer}>
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default TomtomMapComponent;
