import "./style.scss";
import "@tomtom-international/web-sdk-maps/dist/maps.css";

import {
  Badge,
  Button,
  Col,
  Descriptions,
  Form,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { DOStoreList, DOStoreListUpdate, Driver } from "./Driver";
import { GetDateNowBeforeFetchNew, recentTime } from "../../utils/GetDateNow";
import ReactDOMServer, { renderToStaticMarkup } from "react-dom/server";
import {
  TrackingStatusData,
  changeTrackingStatus,
  isLoadingUpdateTrackingStatus,
} from "./SliceUpdateTrackingStatus";
import {
  clearStateGpsTracking,
  driver,
  getDriverDetail,
  getDriverLocation,
  getDriverLocationError,
} from "./Slice";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ApiClient from "../../shared/api/ApiClient";
import { ColumnsType } from "antd/es/table";
import { CopyOutlined } from "@ant-design/icons";
import { GET_LOCATION_HISTORY } from "../../shared/api/ApiEndPoint";
import { LeftOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import ModalChangeStatus from "./Component/ModalChangeStatus";
import type { RadioChangeEvent } from "antd";
import TomtomMapComponent from "../../shared/component/MapComponent/TomtomMapComponent";
import carIconImg from "../../assets/images/icon/icon-car.png";
import dayjs from "dayjs";
import mapSDK from "@tomtom-international/web-sdk-maps";
import { useAppDispatch } from "../../shared/store/store";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LocalstorageService from "../../shared/service/Localstorage.service";
import InfoModal from "../../shared/component/ModalComponent";

const { Content } = Layout;
const spinIcon = <LoadingOutlined rev="" style={{ fontSize: 24, color: "#c62626" }} spin />;

enum ModalComponent {
  NONE,
  CONFIRM_FORM,
  CHANGE_STATUS,
  CHANGE_STATUS_CONFIRM,
  NOT_FOUND_PAGE,
}

export enum Status {
  WAITING = "Waiting",
  IN_TRANSIT = "In-transit",
  SUCCESS = "Success",
  CANCELED = "Canceled",
}

export default function GpsTracking() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tracking_driver_id } = useParams();
  const location = useLocation();
  const [form] = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const driverLocation = useSelector(driver);

  // Loading State
  const userRole = LocalstorageService.getCurrentRole();
  const loadingUpdateStatus = useSelector(isLoadingUpdateTrackingStatus);
  const driverLocationError = useSelector(getDriverLocationError);

  // Set state
  const [otherValue, setOtherValue] = useState("");
  const [reasonValue, setReasonValue] = useState("");
  const [dataUpdate, setDataUpdate] = useState<TrackingStatusData>({
    status: "",
    reason: "",
    note: "",
    driver_mobile_number: "",
    truck_plate_number: "",
    tracking_driver_id: "",
  });
  const [statusValue, setStatusValue] = useState<Status | null>();
  const [successFraction, setSuccessFraction] = useState("0/0");
  const [updateStoreDetail, setUpdateStoreDetail] = useState<DOStoreListUpdate>({
    stop: "",
    storeName: "",
    storeNumber: "",
    currentStatus: "",
    taskID: "",
  });
  const [modalOpen, setModalOpen] = useState<ModalComponent>(ModalComponent.NONE);
  const [messageApi, contextHolderMessage] = message.useMessage();

  // Map
  const tomTomApiKey = process.env.REACT_APP_TOMTOM_API_KEY as string;
  const mapContainer = useRef<HTMLDivElement>(null);
  const tomtomMap = useRef<mapSDK.Map>({} as mapSDK.Map);
  const markers = useRef<Map<string, mapSDK.Marker>>(new Map());
  const [driverDetail, setDriverDetail] = useState<Driver | null>(null);
  const [isLoadingMarker, setIsLoadingMarker] = useState(false);
  const selectedCompany = LocalstorageService.getCompany();
  const [devicesRouteHistory, setDevicesRouteHistory] = useState<any[]>([]);
  const isInitializedRoute = useRef(false);

  const messageError = (message: string) => {
    messageApi.error(message);
  };

  const messageSuccess = (message: string) => {
    messageApi.success(message);
  };

  const store_url = process.env.REACT_APP_STORE_URL_TD;

  function doTypeColor(type: string) {
    switch (type) {
      case "open":
        return "green";
      case "close":
        return "red";
      case "replen":
        return "blue";
      case "other":
        return "purple";
    }
  }

  const disabledActionButton: boolean = userRole === "planner" || userRole === "call_center";

  const columns: ColumnsType<DOStoreList> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => {
        return <div>{(currentPage - 1) * 10 + index + 1}</div>; // Calculate correct index
      },
    },
    {
      title: "หมายเลขDO",
      dataIndex: "do_number",
      key: "do_number",
      render: (_, record) => {
        return <div>{record.do_number ? record.do_number : "-"}</div>;
      },
    },
    {
      title: "DO Type",
      dataIndex: "do_type",
      key: "do_type",
      render: (type, record) => {
        return (
          <div>
            <Tag
              className="truck-driver-do-type"
              color={doTypeColor(record.do_type)}
              data-testid="transport-detail-do-type"
            >
              {record.do_type}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Tracking Number",
      dataIndex: "tracking_number",
      key: "tracking_number",
      render: (text, record) => {
        return (
          <div className="tracking-number-group">
            {record.tracking_number ? (
              <>
                <span className="tracking-number-text">{record.tracking_number}</span>
                <Tooltip title="Copy URL">
                  <a
                    data-testid="copy-to-clipboard-buton"
                    onClick={() =>
                      copyToClipBoard(
                        `${store_url}/t/${
                          record.tracking_number_uuid === ""
                            ? record.tracking_number
                            : record.tracking_number_uuid
                        }?ct=y&company=${selectedCompany}`,
                      )
                    }
                  >
                    <CopyOutlined rev={""} />
                  </a>
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: "หมายเลขสโตร์",
      dataIndex: "store_number",
      key: "store_number",
      render: (text, record) => {
        return (
          <a
            style={{ textDecoration: "underline", color: "#000000" }}
            onClick={() => {
              moveToMarker(record.store_number);
            }}
          >
            {record.store_number}
          </a>
        );
      },
    },
    {
      title: "Stop",
      dataIndex: "stop",
      key: "stop",
      align: "center",
    },
    {
      title: "ชื่อสโตร์",
      dataIndex: "store_name",
      key: "store_name",
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "ETA",
      dataIndex: "eta",
      key: "eta",
      render: (text, record) => {
        return (
          <div>
            {dayjs(record.eta_start).format("HH:mm")} - {dayjs(record.eta_end).format("HH:mm")}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record) => {
        const storeName = record.store_name;
        const storeNumber = record.store_number;
        const currentStatus = record.status;
        const taskID = record.task_id;
        return (
          <div className="gps-wrap-action">
            <Button
              disabled={disabledActionButton}
              onClick={() => {
                form.resetFields();
                setStatusValue(null);
                setUpdateStoreDetail({
                  stop: record.stop,
                  storeName: storeName,
                  storeNumber: storeNumber,
                  currentStatus: currentStatus,
                  taskID: taskID,
                });
                setModalOpen(ModalComponent.CHANGE_STATUS);
                setOtherValue("");
              }}
            >
              Change Status
            </Button>
          </div>
        );
      },
    },
    {
      title: "Report",
      dataIndex: "report",
      key: "report",
      align: "center",
      render: (_, record) => {
        return (
          <a
            style={{ textDecoration: "underline", color: "#000000" }}
            onClick={() => {
              navigate(`/report-detail/${tracking_driver_id}/${record.store_number}`);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            data-testid={`see-report-button`}
          >
            See Report
          </a>
        );
      },
    },
    // waiting for next release
    // {
    //   title: "Note",
    //   dataIndex: "note",
    //   key: "note",
    //   align: "center",
    //   render: (_, record) => {
    //     return <PopoverAction record={record} userRole={userRole} onAction={handleAction} />;
    //   },
    // },
  ];

  // const handleAction = (result: { type: string; record: DOStoreList }) => {
  //   if (result.type === "change_status") {
  //     handleChangeStatus(result.record);
  //   }
  //   if (result.type === "view_report") {
  //     navigate(`/report-detail/${tracking_driver_id}/${result.record.store_number}`);
  //     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   }
  // };

  // const handleChangeStatus = (record: DOStoreList) => {
  //   const storeName = record.store_name;
  //   const storeNumber = record.store_number;
  //   const currentStatus = record.status;

  //   form.resetFields();
  //   setStatusValue(null);
  //   setUpdateStoreDetail({
  //     stop: record.stop,
  //     storeName: storeName,
  //     storeNumber: storeNumber,
  //     currentStatus: currentStatus,
  //   });
  //   setModalOpen(ModalComponent.CHANGE_STATUS);
  //   setOtherValue("");
  // };

  const getSuccessFraction = (data: DOStoreList[]): string => {
    const storeCount: Set<string> = new Set();
    const successCount: Set<string> = new Set();
    data.forEach((item) => {
      if (item.status === Status.SUCCESS.toLowerCase() || item.status === Status.CANCELED.toLowerCase()) {
        successCount.add(item.store_number);
      }
      storeCount.add(item.store_number);
    });
    const uniqueStoreCount = storeCount.size;
    const uniqueSuccessCount = successCount.size;

    return `${uniqueSuccessCount}/${uniqueStoreCount}`;
  };

  useEffect(() => {
    const storeList: DOStoreList[] = driverLocation?.do_store_list || [];
    setSuccessFraction(getSuccessFraction(storeList));
  }, [
    driverLocation?.do_store_list,
    driverLocation?.lat,
    driverLocation?.long,
    driverLocation?.store_location_list,
  ]);

  useEffect(() => {
    clearMarker();
    markers.current = new Map();
    renderMap();
    getTripDetail();
  }, []);

  useEffect(() => {
    if (driverLocationError && driverLocationError.status === 404) {
      setModalOpen(ModalComponent.NOT_FOUND_PAGE);
    }
  }, [driverLocationError]);

  useEffect(() => {
    if (tracking_driver_id) {
      dispatch(getDriverLocation({ trackingDriverID: tracking_driver_id }));
    }

    return () => {
      dispatch(clearStateGpsTracking());
    };
  }, [dispatch, location]);

  useEffect(() => {
    if (driverLocation !== null) {
      const interval = setInterval(() => {
        tracking_driver_id && dispatch(getDriverLocation({ trackingDriverID: tracking_driver_id }));
        getTripDetail();
      }, 2 * (1000 * 60));
      return () => {
        clearInterval(interval);
        setModalOpen(ModalComponent.NONE);
      };
    }
  }, [dispatch, location.state, driverLocation]);

  const initializeRouteHistory = async () => {
    if (tracking_driver_id !== undefined) {
      try {
        const routeHistory = await fetchRouteHistory(tracking_driver_id);
        drawRouteHistory(routeHistory);
        setDevicesRouteHistory(routeHistory);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const updateRouteHistory = async () => {
    if (tracking_driver_id !== undefined && isInitializedRoute.current) {
      try {
        const routeHistory = await fetchRouteHistory(tracking_driver_id);
        drawRouteHistory(routeHistory);
        setDevicesRouteHistory(routeHistory);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    setIsLoadingMarker(true);
    if (driverDetail) {
      updateRouteHistory();
      const truck_plate_number = driverDetail.truck_plate_number;
      const dc_code = driverDetail.dc_code;

      const dcLat = driverDetail.dc_location.lat;
      const dcLong = driverDetail.dc_location.long;
      const truckLat = driverDetail.lat;
      const truckLong = driverDetail.long;

      if (truckLat && truckLong) {
        const htmlContent = popOverPositionContentHTML(truckLat, truckLong);
        if (!markers.current) {
          setMarker(truck_plate_number, "truck-marker", truckLat, truckLong, htmlContent);
        } else {
          if (!markers.current.get(truck_plate_number)) {
            setMarker(truck_plate_number, "truck-marker", truckLat, truckLong, htmlContent);
          } else {
            // setLocationMarker(truck_plate_number, truckLat, truckLong);
          }
        }
      }

      if (dcLat && dcLong) {
        const htmlContent = popOverPositionContentHTML(dcLat, dcLong);
        if (!markers.current) {
          setMarker(dc_code, "dc-marker", dcLat, dcLong, htmlContent);
        } else {
          if (!markers.current.get(dc_code)) {
            setMarker(dc_code, "dc-marker", dcLat, dcLong, htmlContent);
          } else {
            setLocationMarker(dc_code, dcLat, dcLong);
          }
        }
      }

      if (driverDetail.store_location_list) {
        driverDetail.store_location_list.forEach((x: any, index) => {
          const htmlContent = popOverContentHTML(x.store_number, x.lat, x.long);
          if (!markers.current) {
            setStoreMarker(x.store_number, index + 1, x.lat, x.long, htmlContent);
          } else {
            if (!markers.current.get(x.store_number)) {
              setStoreMarker(x.store_number, index + 1, x.lat, x.long, htmlContent);
            } else {
              setLocationMarker(x.store_number, x.lat, x.long);
            }
          }
        });
      }
      setIsLoadingMarker(false);
    }
  }, [driverDetail]);

  useEffect(() => {
    const handleMaploaded = async () => {
      await initializeRouteHistory();
      isInitializedRoute.current = true;
    };
    tomtomMap.current.on("load", handleMaploaded);
    return () => {
      tomtomMap.current.off("load", handleMaploaded);
    };
  }, []);

  const getTripDetail = () => {
    if (tracking_driver_id) {
      getDriverDetail(tracking_driver_id)
        .then((response) => {
          setDriverDetail(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const copyToClipBoard = async (responseText: string) => {
    try {
      await navigator.clipboard.writeText(responseText);
      message.success("คัดลอกเรียบร้อยแล้ว");
    } catch (err) {
      message.error("คัดลอกไม่สำเร็จ");
    }
  };

  const onSubmitFormModal = async (values: any) => {
    setOtherValue(values.note);
    setDataUpdate({
      status: values.status.toLowerCase(),
      reason: values.reason,
      note: values.note ? values.note : "",
      driver_mobile_number: driverLocation?.driver_mobile_number,
      truck_plate_number: driverLocation?.truck_plate_number,
      tracking_driver_id: tracking_driver_id,
    });
    setModalOpen(ModalComponent.CHANGE_STATUS_CONFIRM);
  };

  const onSubmitConfirm = async () => {
    const trackingStatusID = updateStoreDetail.taskID ?? "";

    const response = await dispatch(
      changeTrackingStatus({
        dataUpdate: dataUpdate,
        trackingStatusID: trackingStatusID,
      }),
    );

    if (response.meta.requestStatus === "fulfilled") {
      setModalOpen(ModalComponent.NONE);
      dispatch(getDriverLocation({ trackingDriverID: tracking_driver_id }));
      messageSuccess(`อัพเดทสถานะร้านค้า ${updateStoreDetail.storeNumber} เรียบร้อยแล้ว`);
    } else if (response.meta.requestStatus === "rejected") {
      setModalOpen(ModalComponent.NONE);
      messageError(`อัพเดทสถานะร้านค้า ${updateStoreDetail.storeNumber} ไม่สำเร็จ`);
    }
  };

  const onChangeReason = (e: RadioChangeEvent) => {
    setReasonValue(e.target.value);
  };

  const onChangeStatus = (value: any) => {
    setStatusValue(value);
  };

  const onCancel = () => {
    setReasonValue("");
    setModalOpen(ModalComponent.NONE);
  };

  const deliveryDate = (): string => {
    const dateNow = dayjs().format("DD/MM/YYYY");
    const arrivalDate = dayjs(driverLocation?.arrival_date, "DD-MM-YYYY").format("DD/MM/YYYY");

    if (
      arrivalDate !== dateNow && // not today
      arrivalDate <= dateNow // not more than today
    ) {
      return arrivalDate;
    } else {
      return GetDateNowBeforeFetchNew();
    }
  };

  const headerTransportDetail = (
    <div className="wrap-top-title-transport-detail">
      <div>{`ข้อมูลรถส่งของวันที่ ${deliveryDate()}`}</div>
    </div>
  );

  const fetchRouteHistory = async (tracking_driver_id: string) => {
    return ApiClient.get(GET_LOCATION_HISTORY(tracking_driver_id)).then((response) => {
      const devices = response.data.devices;
      if (devices !== undefined && devices.length > 0) {
        return devices;
      }
      return [];
    });
  };

  const drawRouteHistory = (routesData: any[]) => {
    const sourceNo: Record<string, number> = {};
    if (routesData !== undefined && routesData.length > 0) {
      routesData.forEach((record) => {
        if (!sourceNo[record.source]) {
          sourceNo[record.source] = 1;
        } else {
          sourceNo[record.source]++;
        }
        addMapLayers(tomtomMap.current, sourceNo[record.source], record.coordinates, record.source);
      });
    }
  };

  const getColorCode = (index: number, source: string) => {
    const mobileColors = ["#0F53FF", "#CF0FFF", "#00BF9C", "#B5D300"];
    if (source === "mobile_device") {
      return mobileColors[(index % mobileColors.length) - 1];
    } else {
      return "#FF910F";
    }
  };

  const addMapLayers = (map: any, id: number, coordinates: any[], source: string) => {
    try {
      const layerData = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              properties: {},
              coordinates: coordinates,
            },
          },
        ],
      };

      const lineColor = getColorCode(id, source);
      const layerId = `${source}-route-${id}`;
      const routeLayer = {
        id: layerId,
        type: "line",
        paint: {
          // "line-color": "#0f53ff",
          "line-color": lineColor,
          "line-width": 5,
        },
        layout: {},
        source: {
          type: "geojson",
          data: layerData,
        },
      };

      if (map.getLayer(layerId) !== undefined) {
        map.getSource(layerId).setData(layerData);
      } else {
        map.addLayer(routeLayer);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const changeMapLayerVisibility = (map: any, layerId: string, visibility: "visible" | "none") => {
    map.setLayoutProperty(layerId, "visibility", visibility);
  };

  const renderMap = () => {
    const renderMap = mapSDK.map({
      key: tomTomApiKey as string,
      container: mapContainer.current as HTMLDivElement,
      center: [100.523186, 13.736717],
      zoom: 5.3,
    });
    renderMap.addControl(new mapSDK.NavigationControl({ showCompass: false }), "top-left");

    tomtomMap.current = renderMap;
  };

  const moveToMarker = (key: string) => {
    const map: any = tomtomMap.current;
    const marker = markers.current.get(key);
    if (marker) {
      window.scrollTo(0, 0);
      map.flyTo({
        center: marker.getLngLat(),
        zoom: 13,
        animate: true,
        duration: 4000,
      });
    }
  };
  const setMarker = (
    key: string,
    markerId: string,
    lat: string | undefined,
    long: string | undefined,
    html: string,
  ) => {
    const markerMap = new Map<string, mapSDK.Marker>();

    const markerElement = document.createElement("div");
    markerElement.id = markerId;

    const marker = new mapSDK.Marker({
      element: markerElement,
      anchor: "center",
    });

    const popup = new mapSDK.Popup({ offset: 30 }).setHTML(html);

    marker
      .setLngLat([Number(long), Number(lat)])
      .setPopup(popup)
      .addTo(tomtomMap.current);
    markerMap.set(key, marker);

    markers.current.set(key, marker);
  };

  const setStoreMarker = (
    key: string,
    index: number,
    lat: string | undefined,
    long: string | undefined,
    html: string,
  ) => {
    const markerMap = new Map<string, mapSDK.Marker>();

    const markerElement = document.createElement("div");
    const staticElement = renderToStaticMarkup(RenderStoreMarker(index.toString()));
    markerElement.innerHTML = staticElement;

    const marker = new mapSDK.Marker({
      element: markerElement,
      anchor: "center",
    });

    const popup = new mapSDK.Popup({ offset: 30 }).setHTML(html);

    marker
      .setLngLat([Number(long), Number(lat)])
      .setPopup(popup)
      .addTo(tomtomMap.current);
    markerMap.set(key, marker);

    markers.current.set(key, marker);
  };

  const setLocationMarker = (key: string, lat: string | undefined, long: string | undefined) => {
    const marker = markers.current.get(key);
    if (marker && lat && long) {
      marker.setLngLat([Number(long), Number(lat)]);
    }
  };

  const clearMarker = () => {
    markers.current.clear();
  };

  const popOverContent = (store_number: string, lat: string, long: string) => {
    const storeName = driverDetail?.do_store_list.filter((x) => x.store_number === store_number);
    return (
      <div>
        <p style={{ margin: "10px" }}>ชื่อร้าน {storeName ? storeName[0].store_name : ""}</p>
        <a
          style={{ display: "block", textAlign: "center" }}
          target="_blank"
          href={`https://www.google.com/maps/dir/${lat}, ${long}`}
          rel="noreferrer"
        >
          เปิดใน Google Maps
        </a>
      </div>
    );
  };

  const popOverPositionContent = (lat: string, lng: string) => {
    return (
      <span>
        <p style={{ margin: "10px" }}>{`${lat}, ${lng}`}</p>
        <a
          style={{ display: "block", textAlign: "center" }}
          target="_blank"
          href={`https://www.google.com/maps/dir/${lat}, ${lng}`}
          rel="noreferrer"
        >
          เปิดใน Google Maps
        </a>
      </span>
    );
  };

  const RenderStoreMarker = (index: string) => {
    return (
      <div className="wrap-store-marker">
        <span className="stop">{index}</span>
        <div id="store-marker"></div>
      </div>
    );
  };

  const popOverContentHTML = (store_number: string, lat: string, long: string) =>
    ReactDOMServer.renderToStaticMarkup(<div>{popOverContent(store_number, lat, long)}</div>);

  const popOverPositionContentHTML = (lat: string, long: string) =>
    ReactDOMServer.renderToStaticMarkup(<div>{popOverPositionContent(lat, long)}</div>);

  const sourceOptions = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Mobile Device",
      value: "mobile_device",
    },
    {
      label: "GPS Hardware",
      value: "gps_hardware",
    },
  ];

  const [selectedSource, setSelectedSource] = useState("all");

  const onSelectSource = (value: string) => {
    setSelectedSource(value);
    const sourceNo: Record<string, number> = {};
    devicesRouteHistory.forEach((record) => {
      if (!sourceNo[record.source]) {
        sourceNo[record.source] = 1;
      } else {
        sourceNo[record.source]++;
      }
      const layerId = `${record.source}-route-${sourceNo[record.source]}`;
      if (value === "all") {
        changeMapLayerVisibility(tomtomMap.current, layerId, "visible");
      } else {
        if (record.source === value) {
          changeMapLayerVisibility(tomtomMap.current, layerId, "visible");
        } else {
          changeMapLayerVisibility(tomtomMap.current, layerId, "none");
        }
      }
    });
  };

  const onClickNotFound = () => {
    navigate("/live-monitoring");
    setModalOpen(ModalComponent.NONE);
  };

  return (
    <>
      <Content className="management-content gps-tracking">
        <div className="top-navigate">
          <Row>
            <Col flex={"auto"}>
              <Button
                onClick={() => {
                  navigate("/live-monitoring");
                }}
                icon={<LeftOutlined rev="" />}
              >
                Truck Monitor
              </Button>
            </Col>
            <Col span={4}>
              <Form.Item label="Source">
                <Select
                  style={{ margin: 0 }}
                  options={sourceOptions}
                  value={selectedSource}
                  onChange={(value) => onSelectSource(value)}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Spin indicator={spinIcon} spinning={isLoadingMarker}>
          <section className="wrap-google-map">
            <TomtomMapComponent apiKey={tomTomApiKey} mapContainer={mapContainer}></TomtomMapComponent>
          </section>
        </Spin>

        {driverLocation ? (
          <section className="wrap-transport-detail">
            <Descriptions title={headerTransportDetail} bordered>
              <Descriptions.Item label="คลัง" className="dc-code">
                <span
                  onClick={() => {
                    if (driverLocation && driverLocation.dc_location) {
                      moveToMarker(driverLocation.dc_code);
                    }
                  }}
                  data-testid="transport-detail-dc"
                >
                  {driverLocation.dc_code}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="โหลด">
                <span data-testid="transport-detail-load">
                  {driverLocation.load && driverLocation.load != "" ? driverLocation.load : "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Delivery SLA">
                <span data-testid="transport-detail-sla">{driverLocation.sla}</span>
              </Descriptions.Item>
              <Descriptions.Item label="ป้ายทะเบียน">{driverLocation?.truck_plate_number}</Descriptions.Item>
              <Descriptions.Item label="เบอร์ติดต่อ" className="mobile-number">
                <a href={`tel:${driverLocation?.driver_mobile_number}`}>
                  {driverLocation?.driver_mobile_number}
                </a>
              </Descriptions.Item>
              <Descriptions.Item label="ประเภทรถ" className="mobile-number">
                <span data-testid="transport-detail-truck-type">{driverLocation?.truck_type}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Planned Date">
                <span data-testid="transport-detail-planned-date">{driverLocation.planned_date}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Planned Time">
                <span data-testid="transport-detail-planned-time">{driverLocation.planned_time}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Arrival Date">
                <span data-testid="transport-detail-arrival-date">{driverLocation.arrival_date}</span>
              </Descriptions.Item>
              <Descriptions.Item label="สถานะ">
                <div className="wrap-badge-status">
                  {driverLocation?.status ? (
                    <Badge status="processing" text="Running" />
                  ) : (
                    <Badge status="error" text="Inactive" />
                  )}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="อัพเดทล่าสุด" span={2}>
                {recentTime(driverLocation?.last_location_timestamp as string)}
              </Descriptions.Item>
              <Descriptions.Item label="ตำแหน่งรถ">
                <div className="car-position">
                  <img
                    className={driverLocation?.lat && driverLocation?.long ? "active" : "inactive"}
                    src={carIconImg}
                    alt="car-position"
                    onClick={() => {
                      if (driverLocation && driverLocation.lat && driverLocation.long) {
                        moveToMarker(driverLocation.truck_plate_number);
                      }
                    }}
                  />
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Task Complete">
                <div data-testid="transport-detail-task-complete">{successFraction}</div>
              </Descriptions.Item>
            </Descriptions>
          </section>
        ) : (
          <Spin indicator={spinIcon}>
            <section className="wrap-transport-detail">
              <Descriptions title={`ข้อมูลรถส่งของวันที่ `} bordered>
                <Descriptions.Item label="คลัง" className="dc-code">
                  N/A
                </Descriptions.Item>
                <Descriptions.Item label="โหลด">N/A</Descriptions.Item>
                <Descriptions.Item label="Delivery SLA">N/A</Descriptions.Item>
                <Descriptions.Item label="ป้ายทะเบียน">N/A</Descriptions.Item>
                <Descriptions.Item label="เบอร์ติดต่อ" className="mobile-number" span={2}>
                  N/A
                </Descriptions.Item>
                <Descriptions.Item label="Planned Date">N/A</Descriptions.Item>
                <Descriptions.Item label="Planned Time">N/A</Descriptions.Item>
                <Descriptions.Item label="Arrival Date">N/A</Descriptions.Item>
                <Descriptions.Item label="สถานะ">N/A</Descriptions.Item>
                <Descriptions.Item label="อัพเดทล่าสุด" span={2}>
                  N/A
                </Descriptions.Item>
                <Descriptions.Item label="ตำแหน่งรถ">N/A</Descriptions.Item>
                <Descriptions.Item label="Task Complete">N/A</Descriptions.Item>
              </Descriptions>
            </section>
          </Spin>
        )}

        <section className="wrap-table-list">
          <div className="wrap-top-action-table">
            <h3>รายการส่งสินค้า</h3>
          </div>
          <Table
            scroll={{ x: 1200 }}
            className="table-eta-list"
            dataSource={driverLocation?.do_store_list}
            rowKey={(record) => `${(driverLocation?.do_store_list?.indexOf(record) ?? 0) + 1}`}
            columns={columns}
            loading={driverLocation?.do_store_list ? false : { indicator: spinIcon }}
            pagination={{
              // Pass pagination prop to Table
              current: currentPage,
              pageSize: 10,
              onChange: (page) => setCurrentPage(page), // Update currentPage state when page changes
            }}
          />
        </section>
      </Content>
      <ModalChangeStatus
        updateStoreDetail={updateStoreDetail}
        open={modalOpen === ModalComponent.CHANGE_STATUS}
        onCancel={onCancel}
        onSubmit={onSubmitFormModal}
        onChangeStatus={onChangeStatus}
        onChangeReason={onChangeReason}
        onSubmitConfirm={onSubmitConfirm}
        statusValue={statusValue || null}
        reasonValue={reasonValue}
        otherValue={otherValue}
        openConfirm={modalOpen === ModalComponent.CHANGE_STATUS_CONFIRM}
        loadingUpdateStatus={loadingUpdateStatus}
      />
      <InfoModal
        title="ข้อมูลไม่สามารถแสดงผลได้"
        open={modalOpen === ModalComponent.NOT_FOUND_PAGE}
        onClickConfirmOK={onClickNotFound}
        confirmText="ย้อนกลับ"
        closeIcon={false}
        children={<p>ข้อมูลที่คุณพยายามเข้าถึงถูกลบไปแล้วหรือไม่สามารถเข้าถึงได้ในขณะนี้</p>}
      />
      {contextHolderMessage}
    </>
  );
}
