import { Card, Flex } from "antd";
import { ButtonDs, Form, FormDs, SelectDs, TextAreaDs } from "design-system";
import { Status } from "../../GpsTracking/GpsTracking";
import { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { useMutation } from "@tanstack/react-query";
import liveMonitoringService from "../services/live-monitoring-service";

export enum TaskStatus {
  WAITING = "Waiting",
  IN_TRANSIT = "In-transit",
  SUCCESS = "Success",
  CANCELED = "Canceled",
  EXCEPTION = "Exception",
}
interface ChangeStatusBoxProps {
  taskId: string;
  currentStatus: TaskStatus;
  onCancel: () => void;
  onSubmitSuccess: ({ newStatus }: { newStatus: string }) => void;
  onSubmitFail: () => void;
}

export interface ChangeStatusForm {
  status: string;
  reason: string;
  note: string;
}

const ChangeStatusBox = ({
  taskId,
  onCancel,
  currentStatus,
  onSubmitSuccess,
  onSubmitFail,
}: ChangeStatusBoxProps) => {
  const [form] = Form.useForm<ChangeStatusForm>();
  const statusList: TaskStatus[] = Object.values(TaskStatus).filter(
    (status) => status !== TaskStatus.EXCEPTION,
  ) as TaskStatus[];
  const [disabledReasonField, setDisabledReasonField] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [reasonOptions, setReasonOptions] = useState<DefaultOptionType[]>([]);
  const formValues = Form.useWatch([], form);

  const updateStatusMutation = useMutation({
    mutationFn: (data: ChangeStatusForm) => liveMonitoringService.updateTaskStatus(taskId, data),
    onSuccess: () => {
      const newStatusValue = formValues.status;
      onSubmitSuccess({ newStatus: newStatusValue });
      form.resetFields();
    },
    onError: () => {
      onSubmitFail();
    },
  });

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setDisabledSubmit(false))
      .catch(() => setDisabledSubmit(true));
  }, [formValues]);

  const statusReasonMapping: Record<string, Record<string, string>> = {
    [Status.SUCCESS]: {
      not_close_job: "Driver did not close the job",
      internet_issue: "Driver encountered internet issue",
      application_issue: "Driver encountered app usage issue",
    },
    [Status.CANCELED]: {
      accident: "Accident",
      store_closed: "Store Closed",
      suspend_delivery: "Suspend Delivery",
      car_malfunction: "Car Malfunction",
    },
    common: {
      close_wrong_job: "Driver close wrong job",
      application_issue: "Driver encountered app usage issue",
      officer_close_job: "Admin close wrong job",
    },
  };

  const clearNote = () => {
    form.resetFields(["note"]);
  };

  const onChangeReason = () => {
    clearNote();
  };

  const handleDisableReasonField = () => {
    const selectedStatus = form.getFieldValue("status");
    if (selectedStatus && selectedStatus !== currentStatus) {
      setDisabledReasonField(false);
    } else {
      setDisabledReasonField(true);
    }
  };

  const onChangeStatus = () => {
    handleDisableReasonField();
    renderReasonOptions();
    form.resetFields(["reason"]);
    clearNote();
  };

  const renderReasonOptions = () => {
    let options: DefaultOptionType[] = [];
    const selectedStatus = form.getFieldValue("status");
    if (selectedStatus) {
      const statusReasons =
        statusReasonMapping[
          selectedStatus === Status.WAITING || selectedStatus === Status.IN_TRANSIT
            ? "common"
            : selectedStatus
        ];
      options = Object.entries(statusReasons).map(([value, label]) => ({
        key: value,
        label,
        value,
      }));
      options = [...options, { key: "other", label: "Other (Please specify)", value: "other" }];
    }
    setReasonOptions(options);
  };

  const onCancelChangeStatus = () => {
    onCancel();
  };

  const onFinish = () => {
    const formValues = {
      ...form.getFieldsValue({}),
      status: form.getFieldValue("status")?.toLowerCase(),
    };

    updateStatusMutation.mutate(formValues);
  };

  return (
    <Card className="change-status-box" data-testid="change-status-box">
      <span className="title">You are editing status</span>
      <div style={{ marginTop: 16 }}>
        <FormDs
          form={form}
          layout="vertical"
          initialValues={{
            status: currentStatus,
          }}
          onFinish={onFinish}
        >
          <Flex gap={16}>
            <Form.Item
              label="Status"
              style={{ flex: 0.6 }}
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please select status",
                },
              ]}
            >
              <SelectDs
                options={statusList.map((status) => ({
                  label: status,
                  value: status,
                  disabled: status === currentStatus,
                }))}
                onChange={onChangeStatus}
                data-testid="change-status-select-status"
              />
            </Form.Item>
            <Form.Item
              label="Reason"
              style={{ flex: 1 }}
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please select reason",
                },
              ]}
            >
              <SelectDs
                placeholder="Select Reason"
                onChange={onChangeReason}
                options={reasonOptions}
                disabled={disabledReasonField}
                data-testid="change-status-select-reason"
              />
            </Form.Item>
          </Flex>
          <Flex gap={16}>
            {form.getFieldValue("reason") === "other" && (
              <Form.Item
                name="note"
                style={{ flex: 1 }}
                label="Other (Provide the reason)"
                rules={[
                  {
                    required: form.getFieldValue("reason") === "other" ? true : false,
                    message: "Please specify reason",
                  },
                ]}
              >
                <TextAreaDs
                  rows={4}
                  maxLength={256}
                  showCount
                  autoSize={false}
                  placeholder="Please enter the reason (max 256 characters)"
                />
              </Form.Item>
            )}
          </Flex>
          <Flex justify="end" gap={16} style={{ marginTop: 16 }}>
            <ButtonDs size="middle" onClick={onCancelChangeStatus} data-testid="change-status-cancel-button">
              Cancel
            </ButtonDs>
            <ButtonDs
              size="middle"
              htmlType="submit"
              type="primary"
              loading={updateStatusMutation.isPending}
              disabled={disabledSubmit}
              data-testid="change-status-save-button"
            >
              Save
            </ButtonDs>
          </Flex>
        </FormDs>
      </div>
    </Card>
  );
};

export default ChangeStatusBox;
